import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  LinearProgress,
  Card,
  Container,
  CardContent,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import useVerifyEmail from './useVerifyEmail';
import { green, red } from '@material-ui/core/colors';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

function LoadingWrapper({ children, loading }) {
  if (loading) {
    return (
      <Box width="100%" p={3}>
        <Typography variant="body2" color="primary" align="center">
          Verifying your email address ...
        </Typography>
        <Box p={1} mt={1}>
          <LinearProgress />
        </Box>
      </Box>
    );
  }

  return children;
}

function Result({ type = '', msg = '', activate }) {
  const { userToken } = useSelector(data => data.auth);

  return (
    <Card style={{ flex: 1 }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gridGap=".5em" p={3}>
          <Typography align="center">
            {type === 'success' ? (
              <VerifiedUserOutlinedIcon
                style={{ fontSize: '5em', color: green[300] }}
              />
            ) : (
              <HighlightOffOutlinedIcon
                style={{ fontSize: '5em', color: red[300] }}
              />
            )}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              color: type === 'success' ? green[300] : red[300],
              marginTop: '0.5em',
              marginBottom: '1em'
            }}
          >
            {msg}
          </Typography>
          <Box display="flex" gridGap="1em">
            {userToken && (
              <Button
                component={Link}
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<EqualizerIcon />}
                to="/app/dashboard"
              >
                Dashboard
              </Button>
            )}
            {!userToken && (
              <Button
                component={Link}
                to={'/app/login'}
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<ExitToAppIcon />}
              >
                Login
              </Button>
            )}
            {type === 'failed' && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                endIcon={<ReplayIcon />}
                onClick={activate}
              >
                Try Again
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

const VerifyEmail = () => {
  const { result, isVerifying, activate } = useVerifyEmail();
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Verify Email">
      <Container maxWidth="sm" className={classes.container}>
        <LoadingWrapper loading={isVerifying}>
          <Result activate={activate} {...result} />
        </LoadingWrapper>
      </Container>
    </Page>
  );
};

export default VerifyEmail;
