import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Loading from 'src/components/Loading';
import { NAVBAR_HEIGHT } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSideBarOpen } from 'src/redux/slices/global';
import RightDrawer from 'src/components/RightDrawer';
import { Announcement } from 'src/components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: NAVBAR_HEIGHT
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector(state => state.global);

  function closeSidebar() {
    dispatch(setIsSideBarOpen(false));
  }

  function toggleSidebar() {
    dispatch(setIsSideBarOpen(!isSidebarOpen));
  }

  return (
    <div className={classes.root}>
      <RightDrawer>
        <TopBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <NavBar
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
          toggleSidebar={toggleSidebar}
        />

        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <Announcement />
            <div className={classes.content}>
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
        {/* </Box> */}
      </RightDrawer>
    </div>
  );
};

export default DashboardLayout;
