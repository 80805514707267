import { useState, useEffect } from 'react';
import { useNotif, useToggle } from 'src/hooks';
import { APIRequestV2 } from 'src/helpers';
import { useSelector } from 'react-redux';

const useActivatePhone = () => {
  const [username, setUserName] = useState('');
  const [otp, setOTP] = useState('');
  const [isActivating, activateLoadOn, activateLoadOff] = useToggle();
  const { current_user, userToken } = useSelector(data => data.auth);
  const notif = useNotif();

  function onChangeUsername(e) {
    const { value } = e.target;
    setUserName(value);
  }

  function onChangeOTP(value) {
    setOTP(value);
  }

  async function activate() {
    activateLoadOn();

    const { success, error } = await APIRequestV2({
      method: 'POST',
      url: '/api/user/phone-activate',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        username,
        otp
      }
    });

    activateLoadOff();

    if (!success) {
      console.log(error);
      notif.error(error?.data?.msg ?? 'Activation Failed');
      return;
    }

    if (!userToken) setUserName('');
    setOTP('');
    notif.success('Phone number successfully activated');
  }

  useEffect(() => {
    if (userToken) setUserName(current_user.username);
  }, []);

  return {
    isAuthenticated: Boolean(userToken),
    username,
    otp,
    onChangeUsername,
    onChangeOTP,
    isActivating,
    activate
  };
};

export default useActivatePhone;
