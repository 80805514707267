import React from 'react';
import {
  AccountBalance,
  Person,
  RecentActors,
  Schedule
} from '@material-ui/icons';
import {
  DebounceTextField,
  SingleDatePicker,
  ContactNumberTextField,
  TinTextField
} from 'src/components';
import moment from 'moment';

export function getPrProps({
  Rate1 = {},
  Rate2 = {},
  multiBiometricID = {},
  multiBioDevices
}) {
  return {
    personalInfo: {
      birthDate: {
        source: 'standard_field',
        Component: SingleDatePicker,
        caption: 'Date of Birth',
        formatValue: value =>
          value ? moment(value).format('MMMM DD, YYYY') : '-'
      },
      gender: {
        caption: 'Gender',
        defaultValue: '',
        isEditable: true,
        options: [
          {
            label: 'Male',
            value: 'male'
          },
          {
            label: 'Female',
            value: 'female'
          }
        ],
        required: true,
        type: 'str',
        ui_component: 'select',
        source: 'pr_kvs'
      },
      maritalStatus: {
        caption: 'Marital Status',
        defaultValue: 'single',
        isEditable: true,
        options: [
          {
            label: 'Single',
            value: 'single'
          },
          {
            label: 'Married',
            value: 'married'
          },
          {
            label: 'Widowed',
            value: 'widowed'
          },
          {
            label: 'Divorced',
            value: 'divorced'
          }
        ],
        required: true,
        type: 'str',
        ui_component: 'select',
        source: 'pr_kvs'
      },
      bankAccount: {
        caption: 'Bank Account',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      }
    },
    bioID: {
      multiBiometricID: {
        caption: 'Biometric ID',
        isEditable: true,
        required: false,
        type: 'json',
        source: 'pr_kvs',
        ui_component: 'multi_flds',
        shouldRender: multiBioDevices,
        ...multiBiometricID
      }
    },
    employmentInfo: {
      department: {
        type: 'department',
        source: 'sub_link',
        caption: 'Department'
      },
      employeeID: {
        caption: 'Employee ID',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      },
      biometricID: {
        caption: 'Biometric ID',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs',
        shouldRender: !multiBioDevices
      },
      position: {
        caption: 'Position',
        isEditable: true,
        required: true,
        type: 'str'
      },
      positionType: {
        caption: 'Position Type',
        defaultValue: 'RF',
        isEditable: true,
        options: [
          {
            label: 'Rank and File',
            value: 'RF'
          },
          {
            label: 'Managerial/Supervisor',
            value: 'MS'
          }
        ],
        required: true,
        type: 'str',
        ui_component: 'select',
        source: 'pr_kvs'
      },
      employmentDate: {
        caption: 'Employment Date',
        isEditable: true,
        required: true,
        type: 'date',
        source: 'pr_kvs'
      },
      employmentStatus: {
        caption: 'Employment Status',
        defaultValue: 'inactive',
        isEditable: true,
        options: [
          {
            label: 'Regular',
            value: 'R'
          },
          {
            label: 'Casual',
            value: 'C'
          },
          {
            label: 'Contractual/Project-Base',
            value: 'CP'
          },
          {
            label: 'Seasonal',
            value: 'S'
          },
          {
            label: 'Probationary',
            value: 'P'
          },
          {
            label: 'Apprentices/Learners',
            value: 'AL'
          },
          {
            label: 'Inactive',
            value: 'inactive'
          }
        ],
        required: true,
        type: 'str',
        ui_component: 'select',
        source: 'pr_kvs'
      },
      position: {
        caption: 'Position',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      },
      monthlySalary: {
        allowZeroValue: false,
        caption: 'Monthly Salary',
        isEditable: true,
        required: true,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries'
      },
      Rate1: {
        allowZeroValue: false,
        caption: 'Rate 1',
        isEditable: true,
        required: true,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries',
        ...Rate1
      },
      Rate2: {
        allowZeroValue: false,
        caption: 'Rate 2',
        isEditable: true,
        required: true,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries',
        ...Rate2
      },
      noMandatoryDeduction: {
        caption: 'No Mandatory Deduction',
        type: 'boolean',
        ui_component: 'boolean',
        source: 'pr_kvs'
      },
      mwe: {
        caption: 'Minimum Wage Earner',
        type: 'boolean',
        ui_component: 'boolean',
        source: 'pr_kvs'
      }
    },
    contactInfo: {
      contactNo: {
        source: 'standard_field',
        Component: ContactNumberTextField,
        caption: 'Contact No.'
      },
      email: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Email'
      },
      address: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Address 1'
      },
      address2: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Address 2'
      },
      addressStreet: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Street'
      },
      addressBrgy: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Barangay'
      },
      addressCity: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'City/Municipality'
      },
      addressProvince: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Province'
      },
      addressZIP: {
        source: 'standard_field',
        Component: DebounceTextField,
        caption: 'Zip Code'
      },
      foreignAddress: {
        caption: 'Foreign Address',
        source: 'pr_kvs',
        type: 'str',
        isEditable: true,
        required: false
      }
    },
    sss: {
      sssNo: {
        caption: 'SSS Number',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      },
      sssCrn: {
        caption: 'CRN',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      },
      baseSalarySSS: {
        isEditable: true,
        allowZeroValue: true,
        caption: 'SSS Base Salary',
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries'
      },
      sssNoErShare: {
        caption: 'No Employer Share',
        type: 'boolean',
        ui_component: 'boolean',
        source: 'pr_kvs'
      }
    },
    gsis: {
      bpNo: {
        caption: 'Business Partner No.',
        isEditable: true,
        required: false,
        type: 'str',
        source: 'pr_kvs'
      },
      gsisNo: {
        caption: 'GSIS ID No.',
        isEditable: true,
        required: false,
        type: 'str',
        source: 'pr_kvs'
      },
      gsisCrn: {
        caption: 'CRN',
        isEditable: true,
        required: true,
        type: 'str',
        source: 'pr_kvs'
      }
    },
    philhealth: {
      philhealthNo: {
        caption: 'Philhealth Number',
        isEditable: true,
        required: false,
        type: 'str',
        source: 'pr_kvs'
      },
      baseSalaryPhilhealth: {
        allowZeroValue: true,
        caption: 'Philhealth Base Salary',
        isEditable: true,
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries'
      },
      phNoErShare: {
        caption: 'No Employer Share',
        type: 'boolean',
        ui_component: 'boolean',
        source: 'pr_kvs'
      }
    },
    pagIBIG: {
      pagibigNo: {
        caption: 'MID Number',
        isEditable: true,
        require: true,
        type: 'str',
        source: 'pr_kvs'
      },
      baseSalaryPagibig: {
        caption: 'Pag-IBIG Base Salary',
        isEditable: true,
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries'
      },
      pagibigVoluntaryEeShare: {
        caption: 'Voluntary Employee Share',
        isEditable: true,
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs'
      },
      pagibigVoluntaryErShare: {
        caption: 'Voluntary Employer Share',
        isEditable: true,
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs'
      },
      pagibigNoErShare: {
        caption: 'No Employer Share',
        type: 'boolean',
        ui_component: 'boolean',
        source: 'pr_kvs'
      }
    },
    tax: {
      TIN: {
        source: 'standard_field',
        Component: TinTextField,
        caption: 'TIN'
      },
      baseSalaryWHT: {
        caption: 'WHT Base Salary',
        isEditable: true,
        required: false,
        type: 'int',
        ui_component: 'number',
        source: 'pr_kvs',
        priv: 'salaries'
      },
      rdoCode: {
        caption: 'RDO Code',
        type: 'str',
        required: false,
        source: 'pr_kvs'
      }
    },
    prevEr: {
      prevErName: {
        type: 'str',
        caption: "Employer's Name",
        isEditable: true,
        required: false,
        source: 'pr_kvs'
      },
      prevErAddress: {
        type: 'str',
        caption: 'Registered Address',
        isEditable: true,
        required: false,
        source: 'pr_kvs'
      },
      pervErZip: {
        type: 'str',
        caption: 'Zip Code',
        isEditable: true,
        required: false,
        source: 'pr_kvs'
      }
    },
    workHours: {
      useSched: {
        caption: 'Use different working hours for this employee',
        isEditable: true,
        required: true,
        type: 'boolean',
        source: 'pr_kvs'
      },
      workHours: {
        props: {
          am_in: {
            caption: 'Shift 1 In',
            isEditable: true,
            required: true,
            type: 'str',
            source: 'pr_kvs',
            ui_component: 'time',
            formatValue: value =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : '-'
          },
          am_out: {
            caption: 'Shift 1 Out',
            isEditable: true,
            required: true,
            type: 'str',
            source: 'pr_kvs',
            ui_component: 'time',
            formatValue: value =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : '-'
          },
          pm_in: {
            caption: 'Shift 2 In',
            isEditable: true,
            required: true,
            type: 'str',
            source: 'pr_kvs',
            ui_component: 'time',
            formatValue: value =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : '-'
          },
          pm_out: {
            caption: 'Shift 2 Out',
            isEditable: true,
            required: true,
            type: 'str',
            source: 'pr_kvs',
            ui_component: 'time',
            formatValue: value =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : '-'
          }
        },
        type: 'json',
        ui_component: 'multi_flds',
        source: 'pr_kvs'
      }
    },
    workDays: {
      useDaySched: {
        caption: 'Use different working days for this employee',
        isEditable: true,
        required: true,
        type: 'boolean',
        source: 'pr_kvs'
      },
      workDays: {
        props: {
          mon: {
            caption: 'Monday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          tue: {
            caption: 'Tuesday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          wed: {
            caption: 'Wednesday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          thu: {
            caption: 'Thursday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          fri: {
            caption: 'Friday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          sat: {
            caption: 'Saturday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          },
          sun: {
            caption: 'Sunday',
            isEditable: true,
            required: true,
            type: 'boolean',
            source: 'pr_kvs'
          }
        },
        type: 'json',
        ui_component: 'multi_flds',
        source: 'pr_kvs'
      }
    },
    workSched: {
      workSched: {
        type: 'array',
        ui_component: 'array',
        source: 'pr_kvs',
        arrayFill: {
          type: 'json',
          ui_component: 'multi_flds',
          props: {
            schedFrom: {
              caption: 'From',
              isEditable: true,
              required: true,
              type: 'date',
              source: 'pr_kvs'
            },
            schedTo: {
              caption: 'To',
              isEditable: true,
              required: true,
              type: 'date',
              source: 'pr_kvs'
            },
            shifts: {
              type: 'array',
              ui_component: 'array',
              source: 'pr_kvs',
              arrayFill: {
                type: 'json',
                props: {
                  shiftIn: {
                    caption: 'Shift In',
                    isEditable: true,
                    required: true,
                    type: 'str',
                    source: 'pr_kvs',
                    ui_component: 'time'
                  },
                  shiftOut: {
                    caption: 'Shift Out',
                    isEditable: true,
                    required: true,
                    type: 'str',
                    source: 'pr_kvs',
                    ui_component: 'time'
                  }
                }
              }
            }
          }
        }
      }
    }
  };
}

export const PR_PROP_TITLE_MAP = {
  personalInfo: 'Personal Information',
  employmentInfo: 'Employment Information',
  contactInfo: 'Contact Information',
  sss: 'SSS',
  gsis: 'GSIS',
  philhealth: 'Philhealth',
  pagIBIG: 'Pag-IBIG',
  tax: 'Withholding Tax',
  prevEr: 'Previous Employer',
  workHours: 'Working Hours',
  workDays: 'Working Days',
  workSched: 'Work Schedule'
};

export const PR_EMP_PROFILE_TABS = [
  {
    id: 'personalInfo',
    key: 'personalInfo',
    title: 'Personal Info',
    sections: ['personalInfo', 'contactInfo'],
    showSectionTitle: false,
    icon: <Person />
  },
  {
    id: 'employmentInfo',
    key: 'employmentInfo',
    title: 'Employment Info',
    sections: ['employmentInfo', 'bioID'],
    showSectionTitle: false,
    icon: <RecentActors />
  },
  {
    id: 'mandatoryDed',
    key: 'mandatoryDed',
    title: 'Mandatory Deductions',
    sections: ['sss', 'gsis', 'philhealth', 'pagIBIG', 'tax'],
    showSectionTitle: true,
    icon: <AccountBalance />
  },
  {
    id: 'workSched',
    key: 'workSched',
    title: 'Work Schedule',
    sections: ['workDays', 'workHours'],
    showSectionTitle: true,
    icon: <Schedule />
  }
];
