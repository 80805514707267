import React, { useState, useEffect } from 'react';
import {
  IconButton,
  TextField,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setLastSearchAccountQuery } from 'src/redux/slices/storedValues';
import SearchDialog from './SearchDialog';
import useAccSearch from '../hooks/useAccSearch';
import SearchIcon from '@material-ui/icons/Search';
import useNotif from 'src/hooks/useNotif';

const useStyles = makeStyles(theme => ({
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  adornStart: {
    paddingLeft: '1px'
  }
}));

const SrchAcc = ({
  selectedAcc = {},
  onSelectAccount = () => null,
  error = false,
  errorMessage = '',
  indent = false,
  caption,
  size,
  allowSelectParent = true,
  required = false,
  accountTypes = [],
  specificTypes = [],
  expenseClass = [],
  view = null,
  showModal = false,
  callBackCloseModal = () => null,
  style = {},
  forceOpenDialog = false,
  showSearchField = true,
  endAdornment = null,
  lib = false,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    loading,
    accountList,
    accountTypes: accTypes,
    query,
    refresh,
    setQuery,
    setAccountTypes,
    setExpenseClass
  } = useAccSearch({ lib });

  const { lastAccountSearchQuery } = useSelector(data => data.storedValues);

  const [open, setOpen] = useState(false);
  const notify = useNotif();

  const handleSrchAcc = e => {
    const value = e.target.value;

    if (value.trim() === '') {
      setQuery(lastAccountSearchQuery.trim());
      setOpen(true);
      return;
    }

    if (value !== '' || typeof value !== 'undefined' || value !== null) {
      const lastChar = value[value.length - 1];
      setQuery(lastChar);
    }

    setOpen(true);
  };

  const handleChangeQuery = event => {
    setQuery(event.target.value);
  };

  const handleAccSelect = item => {
    if (item.lft + 1 !== item.rgt && allowSelectParent === false) {
      notify.warning('Please select non-parent account.');
    } else {
      setOpen(false);
      callBackCloseModal(false);
      dispatch(setLastSearchAccountQuery(query));
      onSelectAccount({
        ...item,
        jeItemIndex: rest.index
      });
    }
  };

  const handleChangeAccountTypesFilter = e => {
    const { name, checked } = e.target;
    const accType = +name;

    setAccountTypes(prev => {
      if (!checked) {
        return prev.filter(prev => prev !== accType);
      }

      return [...prev, accType];
    });
  };

  const handleClose = () => {
    setOpen(false);
    callBackCloseModal(false);
  };

  useEffect(() => {
    if (accountTypes.length) setAccountTypes(accountTypes);
  }, [accountTypes]);

  useEffect(() => {
    if (expenseClass.length) setExpenseClass(expenseClass);
  }, [expenseClass]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  if (!selectedAcc) return null;

  return (
    <>
      <SearchDialog
        open={forceOpenDialog || open}
        accounts={accountList}
        query={query}
        loading={loading}
        onChangeQuery={handleChangeQuery}
        onSelectAccount={handleAccSelect}
        onClose={handleClose}
        refresh={refresh}
        accountTypes={accTypes}
        onChangeAccountTypes={handleChangeAccountTypesFilter}
      />

      {showSearchField && (
        <>
          {Boolean(view) ? (
            view
          ) : (
            <TextField
              fullWidth
              name="SrchAcc"
              value={selectedAcc.title}
              disabled={!(rest?.isEditable ?? true)}
              InputProps={{
                style: { paddingLeft: indent ? '1em' : '' },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="search"
                      onClick={() => setOpen(true)}
                      disabled={!(rest?.isEditable ?? true)}
                      size={size ?? 'medium'}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: endAdornment,
                classes: {
                  adornedStart: classes.adornStart
                }
              }}
              error={error}
              helperText={errorMessage}
              placeholder="Search..."
              variant="outlined"
              label={caption ?? 'Select Account'}
              onChange={handleSrchAcc}
              size={size ?? 'medium'}
              required={required}
              style={style}
            />
          )}
        </>
      )}
    </>
  );
};

export default SrchAcc;
