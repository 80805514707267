import React, { useEffect, useState } from 'react';
import {
  Card,
  IconButton,
  Button,
  makeStyles,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  LinearProgress,
  Box,
  Tooltip,
  Link,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ArrowForward, OpenInNew } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { blue } from '@material-ui/core/colors';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector, useDispatch } from 'react-redux';
import ScrollBar from 'react-perfect-scrollbar';
import DebounceTextField from 'src/components/DebounceTextField';
import columns from './columns';
import useOpenedTransactions from '../hooks/useOpenedTransaction';
import {
  TransCardContainer,
  TransCard,
  TransActions,
  TransContent
} from 'src/components/TransCard';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import CustomChip from 'src/components/CustomChip';
import { getWFColor, getWFStatus } from 'src/helper';
import getixJCd from 'src/helpers/getixJCd';
import moment from 'moment';
import { setOpenedOnCardView } from 'src/redux/slices/dashboard';

const useStyles = makeStyles(theme => ({
  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  },
  cursur: {
    cursor: 'pointer'
  },
  rowNewBorderColor: {
    borderLeft: `5px solid ${blue[500]}`,
    '& .MuiTableCell-root': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  rowBgColor: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

const ITEMS_PER_PAGE = 10;

const LoadingOverlay = () => <LinearProgress style={{ height: 2 }} />;

const OpenedTransactions = ({ className, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wf } = useSelector(state => state.bizMeta);
  const {
    openedTransactions: { onCardView }
  } = useSelector(state => state.dashboard);
  const {
    data,
    loading,
    query,
    setQuery,
    refresh,
    limit,
    page,
    onPageChange,
    onLimitChange
  } = useOpenedTransactions();

  const [paginatedTrans, setPaginatedTrans] = useState([]);
  const [paginationLength, setPaginationLength] = useState(0);
  const [isAppending, setIsAppending] = useState(false);
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggleView = checked => {
    dispatch(setOpenedOnCardView(checked));
  };

  const appendNextPage = () => {
    const len = data.length;
    if (len <= paginationLength) return;

    const diff = len - paginationLength;

    if (diff > ITEMS_PER_PAGE) {
      setPaginatedTrans(data.slice(0, paginationLength + ITEMS_PER_PAGE));
      setPaginationLength(state => state + ITEMS_PER_PAGE);
    } else {
      setPaginatedTrans(data.slice(0, paginationLength + len));
      setPaginationLength(state => state + diff);
    }
  };

  const getTransTypeColor = JCd => {
    const transTypes = Object.entries(wf);
    let color = {
      color: 'default',
      shade: 500
    };

    for (let i = 0; i < transTypes.length; i++) {
      if (transTypes[i][1].JCd === JCd) {
        color.color = transTypes[i][1]?.['wf-settings']?.color || 'default';
        color.shade = transTypes[i][1]?.['wf-settings']?.shade || 500;
        break;
      }
    }

    return color;
  };

  useEffect(() => {
    if (onCardView) {
      const len = data.length;
      if (len < ITEMS_PER_PAGE) {
        setPaginatedTrans(data);
        setPaginationLength(len);
      } else {
        setPaginatedTrans(data.slice(0, 10));
        setPaginationLength(ITEMS_PER_PAGE);
      }
    }
  }, [onSmallScreen, onCardView, query]);

  useEffect(() => {
    setTimeout(() => {
      setIsAppending(false);
    }, 1000);
  }, [paginatedTrans]);

  useEffect(() => {
    if (isAppending) appendNextPage();
  }, [isAppending]);

  useEffect(() => {
    setTimeout(() => {
      handleToggleView(onSmallScreen);
    }, [100]);
  }, [onSmallScreen]);

  return (
    <>
      <Box width="100%" overflow="auto" mt={3}>
        <Box
          display="flex"
          gridGap="1em"
          flexWrap="wrap"
          alignItems="center"
          mb={3}
        >
          <DebounceTextField
            value={query}
            placeholder="Search..."
            variant="outlined"
            onChange={e => setQuery(e.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />

          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={refresh}
            style={{
              marginRight: 'auto'
            }}
          >
            Refresh
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={onCardView}
                onChange={e => {
                  handleToggleView(e.target.checked);
                }}
                size="small"
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="h5" color="textSecondary">
                Card view
              </Typography>
            }
            style={{ marginRight: 0 }}
          />
        </Box>

        <Card className={clsx(classes.root, className)} {...rest}>
          {onCardView ? (
            <Box p={2}>
              <ScrollBar
                onXReachEnd={() => {
                  if (!isAppending) setIsAppending(true);
                }}
              >
                <TransCardContainer mb={2} p={1} style={{ columnGap: '1em' }}>
                  {paginatedTrans.map(item => {
                    const color = getTransTypeColor(item.JCd);
                    const bgColor = getColorFromMUI(color);

                    return (
                      <TransCard
                        showCaption={item?.isNew}
                        color={bgColor}
                        key={item.jid}
                      >
                        <TransContent>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: 700,
                              marginBottom: '4px'
                            }}
                          >
                            {item.Name}
                          </Typography>
                          <Typography variant="caption">
                            Type: {item.JCd}
                          </Typography>
                          <Typography variant="caption">
                            Ref #: {item.RefNo}
                          </Typography>
                          <Typography variant="caption">
                            Date: {moment(item.jDate).format('MMM D, YYYY')}
                          </Typography>
                          <CustomChip
                            color={getWFColor(
                              wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                              item.wfStatus
                            )}
                            label={getWFStatus(
                              wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                              item.wfStatus
                            )}
                            style={{
                              fontSize: '11px',
                              width: 'fit-content'
                            }}
                          />
                        </TransContent>
                        <TransActions>
                          <Tooltip title="Open">
                            <IconButton
                              size="small"
                              color="primary"
                              component={Link}
                              href={'/app/trans/' + item.jid}
                            >
                              <ArrowForward fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Open in new tab">
                            <IconButton
                              size="small"
                              color="primary"
                              component={Link}
                              href={'/app/trans/' + item.jid}
                              target="_blank"
                            >
                              <OpenInNew fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TransActions>
                      </TransCard>
                    );
                  })}
                </TransCardContainer>
              </ScrollBar>
            </Box>
          ) : (
            <DataGrid
              disableColumnMenu
              disableColumnFilter
              style={{ border: 'none' }}
              rows={data}
              columns={columns()}
              page={page}
              onPageChange={onPageChange}
              pageSize={limit}
              onPageSizeChange={onLimitChange}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              getRowId={row => row['jid']}
              autoHeight
              rowHeight={80}
              loading={loading}
              components={{
                LoadingOverlay
              }}
              className={classes.rowBgColor}
              disableSelectionOnClick
              onRowDoubleClick={({ row }) => {
                window.open(`/app/trans/${row.jid}`, '_blank');
              }}
            />
          )}
        </Card>
      </Box>
    </>
  );
};

export default OpenedTransactions;
