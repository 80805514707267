import {
  blue,
  cyan,
  deepOrange,
  green,
  orange,
  purple,
  red,
  teal,
  amber,
  lime,
  grey,
  brown,
  indigo
} from '@material-ui/core/colors';

export const APP_VERSION = '1.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  DEFAULT: 'DEFAULT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  PURPLE: 'PURPLE',
  FLAMINGO: 'FLAMINGO',
  LIME: 'LIME',
  MINT: 'MINT',
  AQUA: 'AQUA',
  COZY: 'COZY',
  FIRE_BREATH: 'FIRE_BREATH',
  IBFRS_THEME: 'IBFRS_THEME'
};

export const SIDEBAR_WIDTH = 256;
export const NAVBAR_HEIGHT = 64;
export const MAX_RECENT_TO_DISPLAY = 4;
export const MAX_BIZ_PER_PAGE = 12;

export const EXCLUDED_REDUCERS = [
  'bizState',
  'auth',
  'registrationReducer',
  'global',
  'registry',
  'announcement',
  'env'
];

export const EXPENSE_CLASS = {
  1: {
    desc: 'Personnel Services',
    shortDesc: 'PS'
  },
  2: { desc: 'Maintenance and Other Operating Services', shortDesc: 'MOOE' },
  3: { desc: 'Financial Expenses', shortDesc: 'FE' },
  4: { desc: 'Capital Outlay', shortDesc: 'CO' }
};

export const JSTATUS = {
  0: 'For Approval',
  1: 'Approved',
  2: 'Disapproved',
  3: 'Cancelled'
};

export const JSTATUS_COLOR = {
  0: blue[400],
  1: green[400],
  2: red[400],
  3: orange[400]
};

export const RU_PRIV_OVERRIDE = ['biz', 'acc', 'user-priv', 'portal'];

export const ULAP_CONFIG = {
  default: {
    name: {
      first_word: 'Ulap',
      second_word: 'Biz'
    },
    logo_url: {
      small: '/static/images/icon_no_white_background.png',
      regular: '/static/images/icon_no_white_background.png',
      medium: '/static/images/icon_no_white_background.png',
      large: '/static/images/icon_no_white_background.png'
    },
    logo_settings: {
      padding: 2,
      background: '#FFF'
    }
  },
  egov: {
    name: {
      first_word: 'i',
      second_word: 'BFRS'
    },
    logo_url: {
      small: '/static/images/iBFRS_transparent.png',
      regular: '/static/images/iBFRS_transparent.png',
      medium: '/static/images/iBFRS_transparent.png',
      large: '/static/images/iBFRS_transparent.png'
    },
    logo_settings: {
      padding: 0,
      background: 'none'
    }
  },
  radztech: {
    name: {
      first_word: '',
      second_word: 'Radztech'
    },
    logo_url: {
      small: '/static/images/radztech_logo.png',
      regular: '/static/images/radztech_logo.png',
      medium: '/static/images/radztech_logo.png',
      large: '/static/images/radztech_logo.png'
    },
    logo_settings: {
      padding: 0,
      background: 'none'
    }
  },
  student: {}
};

export const Timezone = '+08:00';

export const EXP_CLS_COLORS = {
  ps: '#003f5c',
  mooe: '#58508d',
  fe: '#bc5090',
  co: '#ff6361'
};

export const BUDGET_TYPE_COLORS = {
  approp: '#7DA42D',
  allot: '#007AC3',
  oblig: '#FEC659',
  dv: '#B73535'
};

export const BUDGET_TYPE_2_COLORS = {
  approp: '#3366D6',
  allot: '#4285F4',
  oblig: '#A0C2FA',
  dv: '#D0E0FC'
};

export const SIL_TYPES = {
  SICK_LEAVE: 1,
  VACATION_LEAVE: 2,
  MATERNITY_LEAVE: 3,
  PATERNITY_LEAVE: 4,
  BEREAVEMENT_LEAVE: 5,
  UNPAID_LEAVE: 6,
  SIL: 7,
  OTHERS: 9,
  OB: 21,
  OT: 22
};

export const LEAVES = [
  SIL_TYPES.SICK_LEAVE,
  SIL_TYPES.VACATION_LEAVE,
  SIL_TYPES.MATERNITY_LEAVE,
  SIL_TYPES.PATERNITY_LEAVE,
  SIL_TYPES.BEREAVEMENT_LEAVE,
  SIL_TYPES.UNPAID_LEAVE,
  SIL_TYPES.SIL
];

export const SIL_TYPES_W_TIME = [SIL_TYPES.OB, SIL_TYPES.OT];

export const SIL_TYPE_DESCRIPTION = {
  [SIL_TYPES.SICK_LEAVE]: 'Sick Leave',
  [SIL_TYPES.VACATION_LEAVE]: 'Vacation Leave',
  [SIL_TYPES.MATERNITY_LEAVE]: 'Maternity Leave',
  [SIL_TYPES.PATERNITY_LEAVE]: 'Paternity Leave',
  [SIL_TYPES.BEREAVEMENT_LEAVE]: 'Bereavement Leave',
  [SIL_TYPES.UNPAID_LEAVE]: 'Unpaid Leave',
  [SIL_TYPES.OTHERS]: 'Others',
  [SIL_TYPES.OB]: 'Official Business',
  [SIL_TYPES.OT]: 'Over Time',
  [SIL_TYPES.SIL]: 'Service Incentive Leave'
};

export const SIL_MODE = {
  SIL_REQUEST: 1,
  SIL_CREDIT_SETUP: 2
};

export const HOLIDAY_LEVELS = {
  GLOBAL: 1,
  BIZ: 2,
  MERGED: 3
};

export const HOLIDAY_TYPES = {
  REGULAR_DAY: 0,
  REGULAR_HOLIDAY: 1,
  SPECIAL_HOLIDAY: 2
};

export const COLOR_VALUES = {
  SPECIAL_HOLIDAY: blue[200],
  REGULAR_HOLIDAY: green[200],
  LEAVE: purple[200],
  SICK_LEAVE: teal[200],
  VACATION_LEAVE: lime[200],
  ERROR: red[200],
  BLUE: blue[700],
  HR: purple[200],
  SYS: orange[300],
  ABSENT: red[200],
  LATE: orange[200],
  UT: deepOrange[200],
  OT: purple[200],
  OB: orange[200],
  MATERNITY_LEAVE: purple[200],
  PATERNITY_LEAVE: indigo[200],
  UNPAID_LEAVE: grey[400],
  BEREAVEMENT_LEAVE: brown[300],
  REST_DAY: lime[200]
};

export const INVENTORY_TYPES = {
  new: {
    // fifo
    PURCHASES: 100,
    PURCHASE_RETURN: 190,

    SALES: 200,
    PHANTOM_SALES: 250,
    SALES_RETURN: 290,

    STOCK_ADJUSTMENT: 300,
    STOCK_ADJUSTMENT_IN: 320,
    STOCK_ADJUSTMENT_OUT: 330,
    STOCK_ADJUSTMENT_BY_VARIANCE: 340,
    STOCK_ADJUSTMENT_REPRICE: 350,

    STOCK_TRANSFER_BY_WH: 400,
    STOCK_TRANSFER_BY_BRANCH: 410,
    WORK_IN_PROCESS_TRANSFER: 420,

    PURCHASE_ORDER: 501,
    SALES_ORDER: 502,

    // weighted average
    WAVG_PURCHASES: 700,
    WAVG_SALES: 720,
    WAVG_STOCK_IN: 710,
    WAVG_STOCK_OUT: 711,

    DISABLED: 0,
    NONE: 0
  },
  old: {
    // fifo
    PURCHASES: 0,
    PURCHASE_RETURN: 5,

    SALES: 1,
    SALES_RETURN: 6,
    PHANTOM_SALES: 20,

    STOCK_ADJUSTMENT: 3,
    STOCK_ADJUSTMENT_IN: 12,
    STOCK_ADJUSTMENT_OUT: 13,
    STOCK_ADJUSTMENT_BY_VARIANCE: 10,
    STOCK_ADJUSTMENT_REPRICE: 21,

    STOCK_TRANSFER_BY_WH: 8,
    STOCK_TRANSFER_BY_BRANCH: 11,
    WORK_IN_PROCESS_TRANSFER: 19,

    PURCHASE_ORDER: 14,
    SALES_ORDER: 15,

    // weighted average
    WAVG_PURCHASES: 700,
    WAVG_SALES: 720,
    WAVG_STOCK_IN: 710,
    WAVG_STOCK_OUT: 711,

    DISABLED: 999,
    NONE: 0
  }
}['new'];

export const IBFRS_COLORS = {
  DARK_GREEN: '#0399AF',
  LIGHT_GREEN: '#7DD858',
  BLUE: '#086FD8',
  GREEN_BLUE: '#1BE1DE',
  DARK_BLUE: '#002979',
  BLUE_DISABLED: '#005979',
  WHITE: '#FFFFFF'
};

export const PR_MANDATORY_DEDUCTIONS = ['sss', 'pagibig', 'philhealth', 'wht'];

export const DISCOUNT_SELECT_TYPE = {
  ALL_ITEMS: 0,
  SELECTED_ITEMS: 1
};

export const CASH_COUNT = [
  {
    id: 1,
    denomination: 1000,
    qty: 0,
    total: 0
  },
  {
    id: 2,
    denomination: 500,
    qty: 0,
    total: 0
  },
  {
    id: 3,
    denomination: 200,
    qty: 0,
    total: 0
  },
  {
    id: 4,
    denomination: 100,
    qty: 0,
    total: 0
  },
  {
    id: 5,
    denomination: 50,
    qty: 0,
    total: 0
  },
  {
    id: 6,
    denomination: 20,
    qty: 0,
    total: 0
  },
  {
    id: 7,
    denomination: 10,
    qty: 0,
    total: 0
  },
  {
    id: 8,
    denomination: 5,
    qty: 0,
    total: 0
  },
  {
    id: 9,
    denomination: 1,
    qty: 0,
    total: 0
  }
];

export const PR_VARIABLES_W_AUTO_UPDATE = [
  'vPresent',
  'vAbsent',
  'vLate',
  'vUT',

  'vOT',
  'vOT_RH',
  'vOT_SH',
  'vOT_ND',
  'vOT_ND_RH',
  'vOT_ND_SH',
  'vOT_RD',
  'vOT_RD_OT',
  'vOT_RD_RH',
  'vOT_RD_SH',

  'vND',
  'vND_RH',
  'vND_SH',
  'vND_RD',
  'vND_RD_RH',
  'vND_RD_SH',
  'vSH',
  'vRH',

  'var1',
  'var2',
  'var3',
  'var4',
  'var5',
  'var6',
  'var7',
  'var8',
  'var9'
];

export const PR_VARIABLES_CONFIG = {
  vPresent: {
    label: 'Days Present',
    unit: 'days'
  },
  vAbsent: {
    label: 'Days Absent',
    unit: 'days'
  },
  vLate: {
    label: 'Mins Late',
    unit: 'minutes'
  },
  vUT: {
    label: 'Mins Undertime',
    unit: 'minutes'
  },
  vOT: {
    label: 'Overtime',
    unit: 'hours'
  },
  vOT_RH: {
    label: 'OT on Reg Hol',
    unit: 'hours'
  },
  vOT_SH: {
    label: 'OT on Spe Hol',
    unit: 'hours'
  },
  vOT_ND: {
    label: 'ND on OT',
    unit: 'hours'
  },
  vOT_ND_RH: {
    label: 'ND on OT and RH',
    unit: 'hours'
  },
  vOT_ND_SH: {
    label: 'ND on OT and SH',
    unit: 'hours'
  },
  vOT_RD: {
    label: 'Work on Rest Day',
    unit: 'hours'
  },
  vOT_RD_OT: {
    label: 'OT on Rest Day',
    unit: 'hours'
  },
  vOT_RD_RH: {
    label: 'OT on Rest Day and RH',
    unit: 'hours'
  },
  vOT_RD_SH: {
    label: 'OT on Rest Day and SH',
    unit: 'hours'
  },
  vND: {
    label: 'Night Diff.',
    unit: 'hours'
  },
  vND_RH: {
    label: 'Night Diff. on RH',
    unit: 'hours'
  },
  vND_SH: {
    label: 'Night Diff. on SH',
    unit: 'hours'
  },
  vND_RD: {
    label: 'Night Diff. on Rest Day',
    unit: 'hours'
  },
  vND_RD_RH: {
    label: 'Night Diff. on RD & RH',
    unit: 'hours'
  },
  vND_RD_SH: {
    label: 'Night Diff. on RD & SH',
    unit: 'hours'
  },

  vSH: {
    label: 'Special Holidays',
    unit: 'days'
  },
  vRH: {
    label: 'Regular Holidays',
    unit: 'days'
  }
};

export const PR_PRINT_LAYOUT_CODES = ['earn', 'ded', 'ps', 'register'];

export const BIZ_PLANS = {
  BIZ_BASIC: 100,
  PR_BASIC: 110,
  PR_BASIC_PLUS: 111,
  BIZ_PRO: 200,
  PR_PRO: 210,
  BIZ_AGENCY: 290,
  BIZ_ENTERPRISE: 300,
  PR_ENTERPRISE: 310
};

export const ACCOUNT_TYPES = {
  ASSETS: 1,
  LIABILITIES: 2,
  EQUITY: 3,
  REVENUES: 4,
  EXPENSES: 5,
  OTHER_INCOME: 6,
  OTHER_EXPENSES: 7
};

export const ACCOUNT_STYPE = {
  INVENTORY: 101,
  CHECKS_ON_HAND: 105,
  PPE: 109,
  SERVICE_INCOME: 407,
  CIB_CHECKING_ACC: 107
};

export const TAX_SETTINGS = {
  TAX_DISABLED: 0,
  ON_DEBIT: 1,
  ON_CREDIT: 2
};

export const PR_TYPES = {
  MONTHLY: 0,
  SEMI_MONTHLY: 1,
  WEEKLY: 2,
  PIECE_RATE: 3
};

export const CHECK_MODES = {
  JV: 0,
  JE: 1
};

export const BUDGET_TYPES = {
  APPROP: 1,
  ALLOT: 2,
  OBLIG: 3,
  DV: 4,
  APPROP_ADJ: 11,
  SUPP: 12,
  APPROP_BUD_ADJ: 13
};

export const UNITS = {
  PCS: 0,
  SC: 1,
  CS: 2
};

export const IN_INV = [
  INVENTORY_TYPES.PURCHASES,
  INVENTORY_TYPES.PURCHASE_ORDER,
  INVENTORY_TYPES.PURCHASE_RETURN,
  INVENTORY_TYPES.STOCK_ADJUSTMENT_IN,
  INVENTORY_TYPES.WAVG_PURCHASES,
  INVENTORY_TYPES.WAVG_STOCK_IN
];

export const OUT_INV = [
  INVENTORY_TYPES.SALES,
  INVENTORY_TYPES.SALES_RETURN,
  INVENTORY_TYPES.SALES_ORDER,
  INVENTORY_TYPES.PHANTOM_SALES,
  INVENTORY_TYPES.STOCK_ADJUSTMENT_OUT,
  INVENTORY_TYPES.STOCK_TRANSFER_BY_BRANCH,
  INVENTORY_TYPES.STOCK_TRANSFER_BY_WH,
  INVENTORY_TYPES.WAVG_SALES,
  INVENTORY_TYPES.WAVG_STOCK_OUT
];
export const INV_MAX_ROWS = 200;

export const PR_MODES = {
  STANDARD: 0,
  DAILY: 1,
  HOURLY: 2
};
