import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  Zoom
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import { createTheme } from './theme';
import usePreferences from './hooks/usePreferences';
import ConfirmDialog from 'src/components/ConfirmDialog';
import ImageViewerProvider from './components/FileViewer/ImageViewer/ImageViewerProvider';
import ReactCanvasConfetti from 'react-canvas-confetti';
import useConfetti from './hooks/useConfetti';
import useRouteTo from './routes/useRouteTo';
import CashierLog from './components/CashierLog';
import { useEnv } from './hooks';
import { MultiSubSelectProvider, TransSearchProvider } from './contexts';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const confettiStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

const App = () => {
  const { preferences } = usePreferences();
  const { getInstance } = useConfetti();
  const { routeType, router } = useRouteTo();
  const { EGOV } = useEnv();

  const isGuestOrRegistry = ['Guest', 'Registry'].includes(routeType);

  const direction = isGuestOrRegistry ? 'ltr' : preferences.direction;
  const responsiveFontSizes = isGuestOrRegistry;
  const selectedTheme = isGuestOrRegistry
    ? EGOV
      ? 'IBFRS_THEME'
      : 'DEFAULT'
    : preferences.theme;
  const mode = isGuestOrRegistry ? 'light' : preferences.mode;

  const theme = createTheme({
    direction,
    responsiveFontSizes,
    theme: selectedTheme,
    mode,
    EGOV
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            style={{ fontFamily: theme.typography.fontFamily }}
          >
            <ImageViewerProvider>
              <ConfirmDialog>
                <CashierLog>
                  <MultiSubSelectProvider>
                    <TransSearchProvider>
                      <GlobalStyles />
                      {router}
                    </TransSearchProvider>
                  </MultiSubSelectProvider>
                </CashierLog>
              </ConfirmDialog>
            </ImageViewerProvider>
            <ReactCanvasConfetti
              refConfetti={getInstance}
              style={confettiStyles}
            />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
