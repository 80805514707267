import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Card,
  Link,
  Switch,
  FormControlLabel,
  CardHeader,
  makeStyles,
  IconButton,
  Collapse,
  LinearProgress,
  TextField,
  MenuItem,
  InputAdornment,
  Box,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ArrowForward, OpenInNew } from '@material-ui/icons';
import DebounceTextField from 'src/components/DebounceTextField';
import { DataGrid } from '@material-ui/data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import tableColumns from './columns';
import CustomChip from 'src/components/CustomChip';
import { getWFColor, getWFStatus } from 'src/helper';
import ScrollBar from 'react-perfect-scrollbar';
import getixJCd from 'src/helpers/getixJCd';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import moment from 'moment';
import EmptyBox from 'src/icons/Box.png';
import {
  TransCard,
  TransCardContainer,
  TransContent,
  TransActions
} from 'src/components/TransCard';
import {
  setAssignedBranch,
  setAssignedOnCardView,
  setAssignedShowUnseen,
  setShowAssignedTransactions
} from 'src/redux/slices/dashboard';
import { SelectBranch } from 'src/components';

const useStyles = makeStyles(theme => ({
  cursur: {
    cursor: 'pointer'
  },
  rowLeftBorder: {
    borderLeft: `5px solid ${blue[500]}`,
    fontWeight: theme.typography.fontWeightBold,
    '& .MuiTypography-body2': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  rowBgColor: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  },
  transCard: {
    transition: 'all 200ms ease-in-out',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    '&:hover': {
      transform: 'translateY(-.5em)',
      boxShadow: '0 6px 10px 0 rgba(0,0,0,.2)'
    }
  }
}));

const ITEMS_PER_PAGE = 10;

const TableResult = ({
  page,
  limit,
  query,
  loading,
  refresh,
  jcdInfo,
  activeCard,
  activeStatus,
  jcdListError,
  transactions,
  onChangeSearch,
  handlePageChange,
  handleLimitChange,
  transactionStatuses,
  onChangeActiveWFStatus
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const onSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const {
    assignedTransactions: {
      showAssignedTransactions,
      showUnseen,
      onCardView,
      ixBrch
    }
  } = useSelector(state => state.dashboard);
  const { wf, brch } = useSelector(state => state.bizMeta);
  const showBrch = (brch?.mode ?? 0) > 0;
  const columns = useMemo(
    () =>
      tableColumns.map(column =>
        column.field === 'sBrch'
          ? {
              ...column,
              hide: !showBrch,
              headerName: brch?.label_singular ?? column.headerName
            }
          : column
      ),
    [showBrch]
  );

  const [isAppending, setIsAppending] = useState(false);
  const [paginatedTrans, setPaginatedTrans] = useState([]);
  const [paginationLength, setPaginationLength] = useState(0);

  const appendNextPage = () => {
    const len = transactions.length;
    if (len <= paginationLength) return;

    const diff = len - paginationLength;

    if (diff > ITEMS_PER_PAGE) {
      setPaginatedTrans(
        transactions.slice(0, paginationLength + ITEMS_PER_PAGE)
      );
      setPaginationLength(state => state + ITEMS_PER_PAGE);
    } else {
      setPaginatedTrans(transactions.slice(0, paginationLength + len));
      setPaginationLength(state => state + diff);
    }
  };

  const handleToggleOpenCloseTransaction = e => {
    e.stopPropagation();
    dispatch(setShowAssignedTransactions(!showAssignedTransactions));
  };

  const handleToggleView = checked => {
    dispatch(setAssignedOnCardView(checked));
  };

  const handleToggleUnviewed = checked => {
    dispatch(setAssignedShowUnseen(checked));
  };

  const handleChangeBranch = value => {
    dispatch(setAssignedBranch(value));
  };

  const getRowLeftBorder = lastview => {
    return lastview === null ? classes.rowLeftBorder : '';
  };

  const getTransTypeColor = JCd => {
    const transTypes = Object.entries(wf);
    let color = {
      color: 'default',
      shade: 500
    };

    for (let i = 0; i < transTypes.length; i++) {
      if (transTypes[i][1].JCd === JCd) {
        color.color = transTypes[i][1]?.['wf-settings']?.color || 'default';
        color.shade = transTypes[i][1]?.['wf-settings']?.shade || 500;
        break;
      }
    }

    return color;
  };

  useEffect(() => {
    const len = transactions.length;
    setPaginatedTrans(
      len < ITEMS_PER_PAGE ? transactions : transactions.slice(0, 10)
    );
    setPaginationLength(len < ITEMS_PER_PAGE ? len : ITEMS_PER_PAGE);
  }, [transactions]);

  useEffect(() => {
    setTimeout(() => {
      setIsAppending(false);
    }, 1000);
  }, [paginatedTrans]);

  useEffect(() => {
    if (isAppending) appendNextPage();
  }, [isAppending]);

  useEffect(() => {
    setTimeout(() => {
      handleToggleView(onSmallScreen);
    }, [100]);
  }, [onSmallScreen]);

  return (
    <Box width="100%" overflow="auto" mb={3}>
      <Box mb={2}>
        <Card>
          <CardHeader
            className={classes.cursur}
            title={`Transactions (${transactions.length})`}
            action={
              <IconButton
                onClick={handleToggleOpenCloseTransaction}
                size="small"
              >
                {showAssignedTransactions ? (
                  <ExpandLessIcon size="small" />
                ) : (
                  <ExpandMoreIcon size="small" />
                )}
              </IconButton>
            }
            onClick={handleToggleOpenCloseTransaction}
          />
          <Collapse in={showAssignedTransactions}>
            <Box
              style={{
                display: 'flex',
                gap: '1em',
                flexWrap: 'wrap',
                padding: '.5rem',
                alignItems: 'center'
              }}
            >
              <DebounceTextField
                value={query}
                placeholder="Search..."
                variant="outlined"
                onChange={onChangeSearch}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <SelectBranch
                variant="outlined"
                size="small"
                value={ixBrch}
                onChange={handleChangeBranch}
              />

              {activeCard !== '' && jcdListError === '' && (
                <>
                  <TextField
                    value={activeStatus === '' ? 'all' : activeStatus}
                    label="WF Status"
                    variant="outlined"
                    onChange={e => {
                      onChangeActiveWFStatus(
                        e.target.value === 'all' ? '' : e.target.value
                      );
                    }}
                    size="small"
                    select
                  >
                    <MenuItem value="all">-- All --</MenuItem>
                    {transactionStatuses.map(wf => (
                      <MenuItem key={wf.ixStatus} value={wf.ixStatus}>
                        {wf.sStatus}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Button
                    component={Link}
                    href={`/app/trans/jcd/${jcdInfo?.jcd_uuid ?? ''}/new`}
                    target="_blank"
                    color="primary"
                    variant="contained"
                    size="small"
                    underline="none"
                  >
                    {`New ${jcdInfo?.sJCd_singular ?? ''}`}
                  </Button>
                </>
              )}
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={refresh}
                style={{
                  marginRight: 'auto'
                }}
              >
                Refresh
              </Button>
              <FormControlLabel
                control={
                  <Switch
                    checked={onCardView}
                    onChange={e => {
                      handleToggleView(e.target.checked);
                    }}
                    size="small"
                  />
                }
                labelPlacement="start"
                label="Card View"
                style={{ marginRight: 0 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showUnseen}
                    onChange={e => {
                      handleToggleUnviewed(e.target.checked);
                    }}
                    size="small"
                  />
                }
                labelPlacement="start"
                label="Unviewed"
                style={{ marginRight: 0 }}
              />
            </Box>
            {onCardView ? (
              <Box p={2}>
                {transactions.length > 0 ? (
                  <ScrollBar
                    onXReachEnd={() => {
                      if (!isAppending) setIsAppending(true);
                    }}
                  >
                    <TransCardContainer
                      mb={2}
                      p={1}
                      style={{ columnGap: '1em' }}
                    >
                      {paginatedTrans.map(item => {
                        const color = getTransTypeColor(item.JCd);
                        const bgColor = getColorFromMUI(color);
                        return (
                          <TransCard
                            key={item.jid}
                            showCaption={!item?.lastview}
                            color={bgColor}
                          >
                            <TransContent>
                              <Typography
                                variant="caption"
                                style={{
                                  fontWeight: 700,
                                  marginBottom: '4px'
                                }}
                              >
                                {item.Name}
                              </Typography>
                              <Typography variant="caption">
                                Type: {item.JCd}
                              </Typography>
                              <Typography variant="caption">
                                Ref #: {item.RefNo}
                              </Typography>
                              <Typography variant="caption">
                                Date: {moment(item.jDate).format('MMM D, YYYY')}
                              </Typography>
                              <Typography variant="caption">
                                {brch?.label_singular ?? 'Branch'}: {item.sBrch}
                              </Typography>
                              <CustomChip
                                color={getWFColor(
                                  wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                                  item.wfStatus
                                )}
                                label={getWFStatus(
                                  wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                                  item.wfStatus
                                )}
                                style={{
                                  fontSize: '11px',
                                  width: 'fit-content'
                                }}
                              />
                            </TransContent>
                            <TransActions>
                              <Tooltip title="Open">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  component={Link}
                                  href={'/app/trans/' + item.jid}
                                >
                                  <ArrowForward fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Open in new tab">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  component={Link}
                                  href={'/app/trans/' + item.jid}
                                  target="_blank"
                                >
                                  <OpenInNew fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TransActions>
                          </TransCard>
                        );
                      })}
                    </TransCardContainer>
                  </ScrollBar>
                ) : (
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <img
                      src={EmptyBox}
                      alt="Empty Box"
                      style={{ width: 100 }}
                    />
                    <Typography variant="h4" color="primary">
                      Nothing to show!
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <DataGrid
                disableColumnMenu
                disableColumnFilter
                style={{ border: 'none' }}
                rows={transactions}
                columns={columns}
                page={page}
                onPageChange={handlePageChange}
                pageSize={limit}
                onPageSizeChange={handleLimitChange}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                getRowId={row => row['jid']}
                autoHeight
                rowHeight={80}
                headerHeight={40}
                getRowClassName={params => {
                  return getRowLeftBorder(params.row.lastview);
                }}
                components={{
                  LoadingOverlay: LinearProgress
                }}
                className={classes.rowBgColor}
                loading={loading}
                disableSelectionOnClick
                onRowDoubleClick={({ row }) => {
                  window.open(`/app/trans/${row.jid}`, '_blank');
                }}
              />
            )}
          </Collapse>
        </Card>
      </Box>
    </Box>
  );
};

export default TableResult;
