import React from 'react';
import { Typography, Box, Tooltip, IconButton, Link } from '@material-ui/core';
import Chip from 'src/components/WFChip';
import { ArrowRight } from 'react-feather';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';

const columns = () => [
  {
    field: 'refNo',
    headerName: 'Ref #',
    width: 150,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{row.RefNo}</strong> <br />
        {row.JCd}
      </Typography>
    )
  },
  {
    field: 'jDate',
    headerName: 'Date',
    width: 170,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{moment(row.jDate).format('MMM  DD, YYYY')}</strong>
        <br />
        {moment(row.jDate).fromNow()}
      </Typography>
    )
  },
  {
    field: 'Name',
    headerName: 'Name',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{row.Name}</strong> <br />
        {row.Particulars}
      </Typography>
    )
  },
  {
    field: 'WF Status',
    headerName: 'WF Status',
    width: 260,
    renderCell: ({ row }) => <Chip row={row} />
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: ({ row }) => (
      <Box>
        <Tooltip title="Open here">
          <IconButton component={Link} href={'/app/trans/' + row.jid}>
            <ArrowRight fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open in New Tab">
          <IconButton
            component={Link}
            href={'/app/trans/' + row.jid}
            target="_blank"
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
];

export default columns;
