import React, { useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import ExecuteRequest from 'src/components/ExecuteRequest';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { Divider, makeStyles } from '@material-ui/core';
import { useActionRequest } from './useActionRequest';
import { useRecentRequest } from './useRecentRequests';
import { useUserActiveRequest } from './useUserActiveRequest';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  list: {
    height: '500px',
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  tab: { position: 'relative' },
  avatarAssignedTo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  card: {
    ['&:hover']: {
      cursor: 'pointer',
      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`
    }
  }
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const mapStatus = {
  '0': { label: 'Open', color: 'primary' },
  '1': { label: 'Executed', color: 'success' },
  '2': { label: 'Cancelled', color: 'warning' },
  '3': { label: 'Failed', color: 'error' }
};

const AssignedTo = ({ user = '' }) => {
  const classes = useStyles();
  return (
    <Box mt={2} display="flex">
      <Typography
        display="block"
        variant="caption"
        style={{ fontWeight: '500', opacity: 0.6 }}
      >
        Assigned to :
      </Typography>
      <Tooltip title={user}>
        <Avatar
          className={classes.avatarAssignedTo}
          alt={user}
          src={`/api/images/user/${user}/avatar.jpg`}
        >
          {(user?.[0] ?? '').toLocaleUpperCase()}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

const ActionRequestItem = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={data.actionClick}>
      <CardContent>
        <Box display="flex" mb={2}>
          <Avatar src={`/api/images/user/${data.request_by}/avatar.jpg`}>
            {(data.request_by?.[0] ?? '').toLocaleUpperCase()}
          </Avatar>
          <Box ml={1}>
            <Typography
              variant="caption"
              style={{
                fontWeight: 600
              }}
              display="block"
            >
              {data.request_by} - {data.sARQ}
            </Typography>
            <Typography
              variant="caption"
              style={{ opacity: '0.8' }}
              display="block"
            >
              {moment(data.request_date).fromNow()} &#183;{' '}
              <Label color={mapStatus?.[data.status]?.color ?? 'primary'}>
                {mapStatus?.[data.status]?.label ?? 0}
              </Label>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="caption"
            display="block"
            style={{ fontWeight: 400 }}
          >
            {data.request_explanation}
          </Typography>
        </Box>

        {data?.showAssignedTo ? <AssignedTo user={data.assigned_to} /> : null}
      </CardContent>
    </Card>
  );
};

const ActionRequestList = ({ ...props }) => {
  const classes = useStyles();

  if (props.error.status) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={6}>
        <Typography variant="h4" color="error">
          Failed - {props.error.msg}
        </Typography>
      </Box>
    );
  }

  if (props.data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={6}>
        <Typography variant="h4" color="primary">
          {props.loading ? '' : 'No result found'}
        </Typography>
      </Box>
    );
  }

  const handleOnScroll = e => {
    e.preventDefault();

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;

    if (bottom) {
      props.handleNext();
    }
  };

  return (
    <Box p={3} className={classes.list} onScroll={handleOnScroll}>
      <Grid container spacing={3} alignItems="stretch">
        {props.data.map((data, i) => (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={i}>
            <ActionRequestItem key={data.ixARQ} data={data} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const ActionRequest = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openModal, setOpenModal] = useState({
    status: false,
    ixARQ: 0
  });
  const classes = useStyles();
  const arq = useActionRequest();
  const recent = useRecentRequest();
  const activeUserARQ = useUserActiveRequest();

  const handleCloseModal = () => {
    setOpenModal({
      status: false,
      ixARQ: 0
    });
  };

  const handleOpenModal = ({ ...data }) => {
    setOpenModal({
      status: true,
      ...data
    });
  };

  const handleCallBack = e => {
    if (activeTab === 0) {
      arq.refresh();
      return;
    }

    if (activeTab === 1) {
      activeUserARQ.refresh();
    }
  };

  return (
    <Box>
      <AppBar position="static" color="default">
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
        >
          <Tab
            label={`Needs your action (${arq.getListOfArqs().length})`}
            {...a11yProps(0)}
          />
          <Tab
            label={`Your Active Requests (${
              activeUserARQ.getListOfArqs().length
            })`}
            {...a11yProps(1)}
          />
          <Tab
            label={`Recent Requests (${recent.getListOfRecentArqs().length})`}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>

      <Card>
        <TabPanel value={activeTab} index={0} className={classes.tab}>
          <ActionRequestList
            {...{
              data: arq.getListOfArqs().map(data => ({
                ...data,
                actionClick: () =>
                  handleOpenModal({
                    ...{
                      ixARQ: data.ixARQ,
                      showExecute: true,
                      showCancel: true,
                      showRemarks: true,
                      showAssignedTo: false
                    }
                  })
              })),
              loading: arq.loading,
              error: arq.error
            }}
          />
          {arq.loading ? (
            <Box position="absolute" top="1em" right="50%">
              <CircularProgress size={30} />
            </Box>
          ) : null}
          <Divider />
          <Box p={1}>
            <Button
              onClick={arq.refresh}
              color="primary"
              endIcon={<RefreshOutlinedIcon />}
            >
              Refresh
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1} className={classes.tab}>
          <ActionRequestList
            {...{
              data: activeUserARQ.getListOfArqs().map(data => ({
                ...data,
                actionClick: () =>
                  handleOpenModal({
                    ...{
                      ixARQ: data.ixARQ,
                      showExecute: false,
                      showCancel: true,
                      showRemarks: true,
                      showAssignedTo: true
                    }
                  }),
                showAssignedTo: true
              })),
              loading: activeUserARQ.loading,
              error: activeUserARQ.error
            }}
          />
          {activeUserARQ.loading ? (
            <Box position="absolute" top="1em" right="50%">
              <CircularProgress size={30} />
            </Box>
          ) : null}
          <Divider />
          <Box p={1}>
            <Button
              onClick={activeUserARQ.refresh}
              color="primary"
              endIcon={<RefreshOutlinedIcon />}
            >
              Refresh
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={2} className={classes.tab}>
          <ActionRequestList
            {...{
              data: recent.getListOfRecentArqs().map(data => ({
                ...data,
                actionClick: () =>
                  handleOpenModal({
                    ...{
                      ixARQ: data.ixARQ,
                      showExecute: false,
                      showCancel: false,
                      showRemarks: false,
                      showAssignedTo: true
                    }
                  })
              })),

              handleNext: recent.nextPage,
              loading: recent.loading,
              error: recent.error
            }}
          />
          {recent.loading ? (
            <Box position="absolute" top="1em" right="50%">
              <CircularProgress size={30} />
            </Box>
          ) : null}
          <Divider />
          <Box p={1}>
            <Button
              onClick={recent.refresh}
              color="primary"
              endIcon={<RefreshOutlinedIcon />}
            >
              Refresh
            </Button>
          </Box>
        </TabPanel>
      </Card>

      <ExecuteRequest
        open={openModal.status}
        handleClose={handleCloseModal}
        ixARQ={openModal.ixARQ}
        showRemarks={openModal?.showRemarks ?? false}
        showExecute={openModal?.showExecute ?? false}
        showCancel={openModal?.showCancel ?? false}
        showAssignedTo={openModal?.showAssignedTo ?? false}
        callBack={handleCallBack}
      />
    </Box>
  );
};

export default ActionRequest;
