import { escapeRegExp } from 'lodash';

export default ({ data = [], keys = [], query = '' }) => {
  const filter = query.trim();

  if (filter === '') return data;

  const searchRegEx = new RegExp(escapeRegExp(filter), 'i');

  return data.filter(item =>
    keys.some(key => searchRegEx.test((item?.[key] || '').toString()))
  );
};
