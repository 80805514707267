import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  currentTab: 0,
  assignedTransactions: {
    activeCard: '',
    selectedTransChart: 'bar',
    selectedWFChart: 'pie',
    hasAssignedTransactions: false,
    showAssignedTransactions: false,
    onCardView: false,
    showUnseen: false,
    limit: 5,
    query: '',
    ixBrch: 0
  },
  openedTransactions: {
    onCardView: false,
    limit: 5,
    query: ''
  },
  watchedTransactions: {
    showWatchedTransaction: false,
    onCardView: false,
    showUnseen: false,
    hasWatchTransactions: false
  },
  recentActivities: {
    onCardView: false,
    activityType: 'logs',
    showMentions: false,
    showOtherUsersOnly: false
  },
  postingSummary: {
    chartType: 'bar',
    dateRange: 6,
    trans: []
  }
};

const slice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setAssignedQuery(state, action) {
      state.assignedTransactions.query = action.payload;
    },
    setAssignedLimit(state, action) {
      state.assignedTransactions.limit = action.payload;
    },
    setOpenedQuery(state, action) {
      state.openedTransactions.query = action.payload;
    },
    setOpenedLimit(state, action) {
      state.openedTransactions.limit = action.payload;
    },
    setAssignedActiveCard(state, action) {
      state.assignedTransactions.activeCard = action.payload;
    },
    setAssignedSelectedTransChart(state, action) {
      state.assignedTransactions.selectedTransChart = action.payload;
    },
    setAssignedSelectedWFChart(state, action) {
      state.assignedTransactions.selectedWFChart = action.payload;
    },
    setHasAssignedTransactions(state, action) {
      state.assignedTransactions.hasAssignedTransactions = action.payload;
    },
    setShowAssignedTransactions(state, action) {
      state.assignedTransactions.showAssignedTransactions = action.payload;
    },
    setAssignedOnCardView(state, action) {
      state.assignedTransactions.onCardView = action.payload;
    },
    setAssignedShowUnseen(state, action) {
      state.assignedTransactions.showUnseen = action.payload;
    },
    setAssignedBranch(state, action) {
      state.assignedTransactions.ixBrch = action.payload;
    },
    setShowWatchedTransactions(state, action) {
      state.watchedTransactions.showWatchedTransaction = action.payload;
    },
    setWatchedOnCardView(state, action) {
      state.watchedTransactions.onCardView = action.payload;
    },
    setWatchedShowUnseen(state, action) {
      state.watchedTransactions.showUnseen = action.payload;
    },
    setHasWatchedTransactions(state, action) {
      state.watchedTransactions.hasWatchTransactions = action.payload;
    },
    setOpenedOnCardView(state, action) {
      state.openedTransactions.onCardView = action.payload;
    },
    setRecentOnCardView(state, action) {
      state.recentActivities.onCardView = action.payload;
    },
    setRecentActivityType(state, action) {
      state.recentActivities.activityType = action.payload;
    },
    setRecentShowOtherUsersOnly(state, action) {
      state.recentActivities.showOtherUsersOnly = action.payload;
    },
    setRecentShowMentions(state, action) {
      state.recentActivities.showMentions = action.payload;
    },
    setPostingChartType(state, { payload }) {
      state.postingSummary.chartType = payload;
    },
    setPostingDateRange(state, { payload }) {
      state.postingSummary.dateRange = payload;
    },
    setPostingTransactions(state, { payload }) {
      state.postingSummary.trans = payload;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const dashboardReducer = slice.reducer;

export const {
  setCurrentTab,
  setAssignedActiveCard,
  setAssignedSelectedTransChart,
  setAssignedSelectedWFChart,
  setHasAssignedTransactions,
  setShowAssignedTransactions,
  setAssignedOnCardView,
  setAssignedShowUnseen,
  setAssignedBranch,
  setShowWatchedTransactions,
  setWatchedOnCardView,
  setWatchedShowUnseen,
  setHasWatchedTransactions,
  setOpenedOnCardView,
  setRecentOnCardView,
  setRecentActivityType,
  setRecentShowOtherUsersOnly,
  setRecentShowMentions,
  setPostingChartType,
  setPostingDateRange,
  setPostingTransactions,
  setAssignedQuery,
  setAssignedLimit,
  setOpenedLimit,
  setOpenedQuery,
  clear,
  restore
} = slice.actions;
export default dashboardReducer;
