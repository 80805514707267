import Default from './default';
import OneDarkPro from './oneDarkPro';
import Unicorn from './unicorn';
import Purple from './purple';
import Flamingo from './flamingo';
import Lime from './lime';
import Mint from './mint';
import Aqua from './aqua';
import Cozy from './cozy';
import FireBreath from './fireBreath';
import IBFRS_THEME from './ibfrs';
export default [
  Default,
  OneDarkPro,
  Unicorn,
  Purple,
  Flamingo,
  Lime,
  Mint,
  Aqua,
  Cozy,
  FireBreath,
  IBFRS_THEME
];
