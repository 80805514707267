import { lazy } from 'react';
import RegistryLayout from 'src/layouts/RegistryLayout';
import NotFoundView from 'src/views/errors/NotFoundView';

const SelectBiz = lazy(() => import('src/views/auth/SelectBiz'));
const Biz = lazy(() => import('src/views/registry/Biz'));
const BizInfo = lazy(() => import('src/views/registry/BizInfo'));
const BizAdmin = lazy(() => import('src/views/registry/admin'));
const BizCreateUpdate = lazy(() =>
  import('src/views/registry/admin/Biz/CreateUpdateBiz')
);
const ClassView = lazy(() =>
  import('src/views/registry/admin/Student/components/ClassView')
);
const BizStats = lazy(() => import('src/views/registry/admin/BizStats'));
const Agency = lazy(() => import('src/views/registry/Agency'));
const AgencyView = lazy(() => import('src/views/registry/AgencyView'));
const User = lazy(() => import('src/views/registry/admin/User'));
const SetupPage = lazy(() => import('src/views/registry/admin/Page/SetupPage'));

const registryRoutes = [
  {
    path: '/',
    element: RegistryLayout,
    children: [
      { path: '/', element: SelectBiz },
      { path: '/app/*', element: SelectBiz },
      { path: '/app/registry', element: Biz },
      { path: '/app/agency/admin', element: Agency },
      { path: '/app/agency/admin/:ixBiz', element: AgencyView },
      { path: '/app/registry/admin/:page', element: BizAdmin },
      { path: '/app/registry/admin/page/:ixPage', element: SetupPage },
      {
        path: '/app/registry/admin/users/:user',
        element: User
      },
      {
        path: '/app/registry/admin/biz/:ixBiz/info',
        element: BizStats
      },
      { path: '/app/registry/admin/biz/:type', element: BizCreateUpdate },
      {
        path: '/app/registry/admin/biz/:type/:ixBiz',
        element: BizCreateUpdate
      },
      { path: '/app/registry/biz-info/:ixBiz', element: BizInfo },
      {
        path: '/app/registry/admin/class/:ixClass/:sClass/:teacher',
        element: ClassView
      },
      { path: '*', element: SelectBiz },
      { path: '404', element: NotFoundView }
    ]
  }
];

export default registryRoutes;
