import React from 'react';
import PropTypes from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  SvgIcon,
  TableContainer
} from '@material-ui/core';
import { ArrowRight } from 'react-feather';

const SrchSubResults = ({ subList, handleSubSelect, showEdit }) => {
  if (subList == []) return null;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {subList.map(item => (
            <TableRow
              hover
              onClick={() => handleSubSelect(item)}
              key={item.sub_id}
            >
              <TableCell>{item.sub_id}</TableCell>
              <TableCell>{item.sub_title}</TableCell>
              <TableCell style={{ flex: 1 }} />
              <TableCell align="right">
                {showEdit && (
                  <IconButton
                    onClick={event => handleSubSelect(item, event, 'edit')}
                    tabIndex={-1}
                  >
                    <SvgIcon fontSize="small">
                      <Edit />
                    </SvgIcon>
                  </IconButton>
                )}
                <IconButton
                  onClick={() => handleSubSelect(item)}
                  className="controls"
                >
                  <SvgIcon fontSize="small">
                    <ArrowRight />
                  </SvgIcon>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SrchSubResults.propTypes = {
  className: PropTypes.string,
  subList: PropTypes.array.isRequired,
  handleSubSelect: PropTypes.func
};

export default SrchSubResults;
