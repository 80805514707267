import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  makeStyles,
  Typography,
  ButtonGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  CardActions,
  Switch,
  TextField,
  MenuItem,
  InputAdornment,
  LinearProgress,
  Tooltip,
  IconButton,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  useMediaQuery,
  ButtonBase,
  useTheme
} from '@material-ui/core';
import { ArrowForward, OpenInNew } from '@material-ui/icons';
import DebounceTextField from 'src/components/DebounceTextField';
import useRecentActivities from 'src/hooks/useRecentActivities';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import Logs from './Logs';
import Comments from './Comments';
import CustomChip from 'src/components/CustomChip';
import { getWFColor, getWFStatus } from 'src/helper';
import ScrollBar from 'react-perfect-scrollbar';
import getixJCd from 'src/helpers/getixJCd';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import moment from 'moment';
import { isEmpty } from 'lodash';
import MarkDownImageViewer from 'src/components/MarkDownImageViewer';
import MDEditor from '@uiw/react-md-editor';
import { decodeString } from 'src/helpers/CodeString';
import {
  TransCardContainer,
  TransCard,
  TransContent,
  TransActions
} from 'src/components/TransCard';
import {
  setRecentActivityType,
  setRecentOnCardView,
  setRecentShowMentions,
  setRecentShowOtherUsersOnly
} from 'src/redux/slices/dashboard';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  },
  ellipses: {
    lineClamp: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px'
  },
  cardActions: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textField: {
    padding: theme.spacing(1)
  },
  images: {
    ['& img']: {
      border: '1px solid #ddd' /* Gray border */,
      borderRadius: '4px' /* Rounded border */,
      padding: '5px' /* Some padding */,
      width: '150px' /* Set a small width */
    }
  },
  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  }
}));

let lastFetch = 0;

const RecentActivities = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const { wf } = useSelector(state => state.bizMeta);
  const [log_comment, setLog_Comment] = useState({});

  const {
    recentActivities: {
      onCardView,
      activityType,
      showMentions,
      showOtherUsersOnly
    }
  } = useSelector(state => state.dashboard);

  const onSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      handleToggleView(onSmallScreen);
    }, [100]);
  }, [onSmallScreen]);

  useEffect(() => {
    if (onCardView) {
      resetCardActivities();
      fetchCardActivities();
    }
  }, [onCardView, activityType]);

  const {
    recentActivities,
    loading,
    page,
    rows,
    error,
    next,
    previous,
    fetchRecentActivities,
    onChangeRows,
    fetchCardActivities,
    cardActivities,
    resetCardActivities,
    getComments,
    getLogs
  } = useRecentActivities({
    currentPage: 1,
    activityType,
    mentions: showMentions,
    other_users_only: showOtherUsersOnly,
    query,
    onCardView
  });

  const fetchNext = () => {
    if (lastFetch >= Date.now() - 1000 || loading) return;

    lastFetch = Date.now();
    fetchCardActivities();
  };

  const handleToggleView = checked => {
    dispatch(setRecentOnCardView(checked));
  };

  const getTransTypeColor = JCd => {
    const transTypes = Object.entries(wf);
    let color = {
      color: 'default',
      shade: 500
    };

    for (let i = 0; i < transTypes.length; i++) {
      if (transTypes[i][1].JCd === JCd) {
        color.color = transTypes[i][1]?.['wf-settings']?.color || 'default';
        color.shade = transTypes[i][1]?.['wf-settings']?.shade || 500;
        break;
      }
    }

    return color;
  };

  const setActivityType = type => {
    dispatch(setRecentActivityType(type));
  };

  const setShowMentions = val => {
    dispatch(setRecentShowMentions(val));
  };

  const setExcludeYourActivity = val => {
    dispatch(setRecentShowOtherUsersOnly(val));
  };

  const cardData =
    activityType === 'logs'
      ? getLogs(cardActivities?.logs?.data || [])
      : getComments(cardActivities?.comments?.data || []);

  if (error !== '' && !onCardView) {
    return (
      <>
        <Paper
          component={Box}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt={3}
          p={6}
        >
          <img
            src="/static/images/empty-state/Box.png"
            className={classes.emptyImg}
          />
          <Typography variant="h4" align="center" color="error">
            {error}
          </Typography>
          <Button
            color="primary"
            variant="text"
            onClick={fetchRecentActivities}
            disabled={loading}
          >
            {loading ? 'Please wait...' : 'Try Again'}
          </Button>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={!isEmpty(log_comment)}
        onClose={() => setLog_Comment({})}
      >
        <ScrollBar>
          <DialogContent>
            <Box display="flex" gridGap=".5em">
              <Avatar
                src={`/api/images/user/${log_comment?.username}/avatar.jpg`}
                style={{ width: 20, height: 20 }}
              >
                {log_comment?.username?.[0].toUpperCase()}
              </Avatar>
              <Typography variant="caption">
                <strong>
                  {(log_comment?.username || '').charAt(0).toUpperCase() +
                    (log_comment?.username || '').slice(1)}
                </strong>
                <br />
                {activityType === 'logs' &&
                  (log_comment?.logDescription || '')
                    .split('\n')
                    .map((item, index) => (
                      <span key={index + '-' + log_comment.username}>
                        <span>{item}</span>
                        <br />
                      </span>
                    ))}
                {activityType === 'comments' && (
                  <div data-color-mode="light">
                    <div className="wmde-markdown-var" />{' '}
                    <MarkDownImageViewer
                      content={decodeString(log_comment.comment)}
                    >
                      <MDEditor.Markdown
                        source={decodeString(log_comment.comment)}
                        className={classes.images}
                        style={{ whiteSpace: 'pre-wrap' }}
                      />
                    </MarkDownImageViewer>
                  </div>
                )}
              </Typography>
            </Box>
          </DialogContent>
        </ScrollBar>
      </Dialog>

      <Box
        display="flex"
        gridGap="1em"
        flexWrap="wrap"
        alignItems="center"
        my={3}
      >
        <DebounceTextField
          value={query}
          placeholder="Search..."
          variant="outlined"
          onChange={e => {
            setQuery(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          size="small"
        />
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={activityType}
              onChange={e => {
                setLog_Comment({});
                setActivityType(e.target.value);
              }}
            >
              <FormControlLabel
                value="logs"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="h6" color="textSecondary">
                    Logs
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="comments"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="h6" color="textSecondary">
                    Comments
                  </Typography>
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box mr="auto">
          <Button
            color="primary"
            onClick={fetchRecentActivities}
            size="small"
            variant="contained"
          >
            Refresh
          </Button>
        </Box>

        {activityType === 'comments' && (
          <FormControlLabel
            control={
              <Switch
                checked={showMentions}
                onChange={e => {
                  setShowMentions(e.target.checked);
                }}
                size="small"
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="h6" color="textSecondary">
                Mentions
              </Typography>
            }
            style={{ marginRight: 0 }}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={onCardView}
              onChange={e => handleToggleView(e.target.checked)}
              size="small"
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="h6" color="textSecondary">
              Card view
            </Typography>
          }
          style={{ marginRight: 0 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showOtherUsersOnly}
              onChange={e => {
                setExcludeYourActivity(e.target.checked);
              }}
              size="small"
            />
          }
          labelPlacement="start"
          label={
            <Typography variant="h6" color="textSecondary">
              Other users only
            </Typography>
          }
          style={{ marginRight: 0 }}
        />
      </Box>

      <Card className={classes.root}>
        {loading && <LinearProgress style={{ height: 2 }} />}

        {onCardView ? (
          <Box p={2}>
            {cardData.length > 0 ? (
              <ScrollBar onXReachEnd={fetchNext}>
                <TransCardContainer mb={2} p={1} style={{ columnGap: '1em' }}>
                  {cardData.map((item, index) => {
                    const color = getTransTypeColor(item.JCd);
                    const bgColor = getColorFromMUI(color);
                    const description =
                      (activityType === 'logs'
                        ? item?.logDescription
                        : item?.comment) || '';

                    return (
                      <TransCard
                        key={item.jid + '-' + index}
                        showCaption={item?.isNew}
                        color={bgColor}
                      >
                        <TransContent>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: 700, marginBottom: '4px' }}
                          >
                            {item.Name}
                          </Typography>
                          <Typography variant="caption">
                            Type: {item.JCd}
                          </Typography>
                          <Typography variant="caption">
                            Ref #: {item.RefNo}
                          </Typography>
                          <Typography variant="caption">
                            Date: {moment(item.jDate).format('MMM D, YYYY')}
                          </Typography>
                          <CustomChip
                            color={getWFColor(
                              wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                              item.wfStatus
                            )}
                            label={getWFStatus(
                              wf?.[getixJCd(item?.JCd, wf)]?.wf || {},
                              item.wfStatus
                            )}
                            style={{ fontSize: '11px', width: 'fit-content' }}
                          />
                          <Box display="flex" gridGap=".5em" mt={2}>
                            <Avatar
                              src={`/api/images/user/${item.username}/avatar.jpg`}
                              style={{ width: 20, height: 20 }}
                            >
                              {item?.username[0].toUpperCase()}
                            </Avatar>
                            <Box>
                              <Typography variant="caption">
                                <strong>
                                  {item.username.charAt(0).toUpperCase() +
                                    item.username.slice(1)}
                                </strong>
                              </Typography>
                              <br />
                              {description.length > 15 ? (
                                <>
                                  <Typography variant="caption">
                                    {description.substring(0, 13) + '...'}
                                  </Typography>
                                  <ButtonBase
                                    onClick={() => setLog_Comment(item)}
                                    style={{
                                      padding: '0 4px',
                                      borderRadius: '4px'
                                    }}
                                    component={Link}
                                  >
                                    <Typography variant="caption">
                                      more
                                    </Typography>
                                  </ButtonBase>
                                </>
                              ) : (
                                <Typography variant="caption">
                                  {description}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </TransContent>
                        <TransActions>
                          <Tooltip title="Open">
                            <IconButton
                              size="small"
                              color="primary"
                              component={Link}
                              href={'/app/trans/' + item.jid}
                            >
                              <ArrowForward fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Open in new tab">
                            <IconButton
                              size="small"
                              color="primary"
                              component={Link}
                              href={'/app/trans/' + item.jid}
                              target="_blank"
                            >
                              <OpenInNew fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TransActions>
                      </TransCard>
                    );
                  })}
                </TransCardContainer>
              </ScrollBar>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p={3}
              >
                <img
                  src="/static/images/empty-state/Box.png"
                  className={classes.emptyImg}
                />
                <Typography variant="h4" align="center" color="textSecondary">
                  No {activityType === 'logs' ? 'logs' : 'comments'} found
                </Typography>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    resetCardActivities();
                    fetchCardActivities();
                  }}
                  disabled={loading}
                >
                  {loading ? 'Please wait...' : 'Try again'}
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {activityType === 'logs' && (
              <Logs
                logs={recentActivities?.logs ?? []}
                loading={loading}
                pageSize={rows}
                refresh={fetchRecentActivities}
              />
            )}
            {activityType === 'comments' && (
              <Comments
                comments={recentActivities?.comments ?? []}
                loading={loading}
                refresh={fetchRecentActivities}
                pageSize={rows}
              />
            )}
            <CardActions className={classes.cardActions}>
              <Box display="flex" alignItems="center" style={{ gap: '1em' }}>
                <Typography
                  display="inline"
                  variant="body2"
                  color="textSecondary"
                >
                  Rows per page :
                </Typography>

                <TextField
                  size="small"
                  variant="standard"
                  value={rows}
                  onChange={e => onChangeRows(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textField
                    }
                  }}
                  select
                >
                  {[5, 10, 25, 50, 100].map(data => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <ButtonGroup
                color="primary"
                size="small"
                aria-label="outlined primary button group small"
              >
                <Button onClick={previous} size="small" disabled={loading}>
                  Prev
                </Button>
                <Button size="small" disabled={loading}>
                  {page}
                </Button>
                <Button onClick={next} size="small" disabled={loading}>
                  Next
                </Button>
              </ButtonGroup>
            </CardActions>
          </>
        )}
      </Card>
    </>
  );
};

export default RecentActivities;
