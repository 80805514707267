import React from 'react';
import { merge } from 'lodash';
import { TextField } from '@material-ui/core';
import CustomNumberFormat from './CustomNumberFormat';

const NumericField = ({
  fullWidth = false,
  variant = 'outlined',
  label = '',
  value = 0,
  onChange = (e = { target: { value: 0, name: '' } }) => {},
  disabled = false,
  InputProps = {},
  ...props
}) => {
  return (
    <TextField
      fullWidth={fullWidth}
      variant={variant}
      label={label}
      InputProps={merge(
        {
          inputComponent: CustomNumberFormat,
          inputProps: {
            thousandSeparator: true,
            decimalScale: 2,
            fixedDecimalScale: true,
            style: { textAlign: 'right' }
          }
        },
        InputProps
      )}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  );
};

export default NumericField;
