import React from 'react';
import numeral from 'numeral';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';

const LinearProgressWithLabel = ({ progress }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ height: 16, borderRadius: 12 }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {progress} %
        </Typography>
      </Box>
    </Box>
  );
};

const tableColumns = [
  {
    field: 'desc',
    headerName: 'Description',
    width: 200,
    sortable: false
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    sortable: false
  }
];

export const generatePaySchedTblColumns = (accounts = []) => {
  const columns = cloneDeep(tableColumns);
  accounts.forEach(({ heading }) => {
    columns.push({
      field: heading,
      headerName: 'Receivables - ' + heading,
      width: 160,
      sortable: false,
      align: 'right',
      editable: true,
      type: 'number',
      renderCell: ({ value }) => numeral(value).format('0,0.00')
    });
    columns.push({
      field: 'percentage' + heading,
      headerName: `Receivables - ${heading} (%)`,
      width: 170,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => <LinearProgressWithLabel progress={value} />
    });
    columns.push({
      field: 'paid' + heading,
      headerName: 'Paid',
      width: 120,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => numeral(value).format('0,0.00')
    });
    columns.push({
      field: 'balance' + heading,
      headerName: 'Balance',
      width: 120,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => numeral(value).format('0,0.00')
    });
  });

  return columns;
};

export default generatePaySchedTblColumns;
