import React, { useState } from 'react';
import { Schedule } from '@material-ui/icons';
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';

const TimePicker = ({
  value,
  onChange = () => {},
  name = '',
  label = '',
  variant = 'dialog',
  inputVariant = 'outlined',
  disableToolbar = true,
  fullWidth = false,
  InputProps = {},
  disabled = false,
  error = false,
  helperText = '',
  size = 'medium',
  ...props
}) => {
  const dateToday = moment().format('YYYY-MM-DD');

  const [errorMessage, setErrorMessage] = useState('');

  function updateTime(value) {
    onChange({
      target: { value, name }
    });
  }

  /**
   *
   * @param {moment.Moment} date
   * @param {String} value
   */
  function handleChangeTime(date) {
    if (!date || !date.isValid()) {
      setErrorMessage('Invalid time');
      updateTime(null);
      return;
    }

    updateTime(date.format('HH:mm'));
    setErrorMessage('');
  }

  return (
    <KeyboardTimePicker
      fullWidth={fullWidth}
      label={label}
      value={value ? `${dateToday}T${value}:00` : null}
      onChange={handleChangeTime}
      inputVariant="outlined"
      error={error || Boolean(errorMessage)}
      helperText={helperText || errorMessage}
      variant={variant}
      InputProps={InputProps}
      disabled={disabled}
      KeyboardButtonProps={{ disabled: Boolean(InputProps?.readOnly), size }}
      keyboardIcon={<Schedule fontSize={size} />}
      size={size}
      {...props}
    />
  );
};

export default TimePicker;
