import { ButtonBase, Link, Typography } from '@material-ui/core';
import React from 'react';
import useToggle from 'src/hooks/useToggleV2';

const LongText = ({
  text = '',
  maxLength = 40,
  typographyProps = { variant: 'body2', color: 'textPrimary' },
  disableTypography = false
}) => {
  const [longTextShown, showLongText] = useToggle();

  if (!text) return '';

  if (text.length <= maxLength || longTextShown) {
    if (disableTypography) return text;

    return (
      <Typography
        variant={typographyProps.variant}
        color={typographyProps.color}
      >
        {text}
      </Typography>
    );
  }

  if (disableTypography)
    return (
      <>
        {text.substring(0, maxLength)}{' '}
        <Link onClick={showLongText} style={{ cursor: 'pointer' }}>
          ...
        </Link>
      </>
    );

  return (
    <Typography variant={typographyProps.variant} color={typographyProps.color}>
      {text.substring(0, maxLength)}{' '}
      <Link onClick={showLongText} style={{ cursor: 'pointer' }}>
        ...
      </Link>
    </Typography>
  );
};

export default LongText;
