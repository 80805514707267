import React from 'react';
import Container from '@material-ui/core/Container';
import {
  makeStyles,
  TextField,
  Button,
  Box,
  Typography,
  Paper
} from '@material-ui/core';
import OtpInput from 'react-otp-input';
import Page from 'src/components/Page';
import useActivatePhone from './useActivatePhone';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Link } from 'react-router-dom';

const inputStyle = {
  fontSize: '2rem',
  fontWeight: 600,
  width: '3rem',
  height: '4rem'
};

const containerStyle = {
  display: 'flex',
  gap: '1em',
  justifyContent: 'space-between',
  marginTop: '1em',
  marginBottom: '1em'
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OTP = () => {
  const {
    username,
    otp,
    onChangeOTP,
    onChangeUsername,
    activate,
    isActivating,
    isAuthenticated
  } = useActivatePhone();
  const classes = useStyles();

  const disableActivateBtn =
    otp.length < 6 || username.trim() === '' || isActivating;

  return (
    <Page className={classes.root} title="Phone Activation">
      <Container
        maxWidth="xs"
        style={{ display: 'flex', alignItems: 'center', height: '100%' }}
      >
        <Paper component={Box} p={4}>
          <Typography variant="h2" color="primary">
            Phone Activation
          </Typography>

          {
            <Box mb={3} mt={2}>
              <TextField
                label="Username"
                fullWidth
                margin="normal"
                onChange={onChangeUsername}
                placeholder="e.g. juandelacruz123"
                variant="outlined"
                value={username}
                disabled={isAuthenticated}
              />
            </Box>
          }
          <Box mb={3}>
            <Typography variant="h4" color="primary">
              Enter Verification Code
            </Typography>
            <OtpInput
              value={otp}
              onChange={onChangeOTP}
              containerStyle={containerStyle}
              inputStyle={inputStyle}
              numInputs={6}
              separator=""
              isInputNum
              shouldAutoFocus
            />
          </Box>
          <Box display="flex" flexDirection="column" gridGap="1em">
            <Button
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              disabled={disableActivateBtn}
              onClick={activate}
              endIcon={<VerifiedUserOutlinedIcon />}
            >
              Activate
            </Button>

            {!isAuthenticated && (
              <Button
                component={Link}
                to="/app/login"
                size="large"
                color="primary"
                fullWidth
                startIcon={<ArrowBackOutlinedIcon />}
              >
                Back to Sign In
              </Button>
            )}
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default OTP;
