import { useDispatch, useSelector } from 'react-redux';
import { saveBizState } from 'src/redux/slices/bizState';
import {
  restore as restoreCustomReport,
  clear as clearCustomReport
} from 'src/redux/slices/customReport';
import {
  restore as restoreDaloy,
  clear as clearDaloy
} from 'src/redux/slices/daloy';
import { restore as restoreGL, clear as clearGL } from 'src/redux/slices/gl';
import {
  restore as restoreOrderSummary,
  clear as clearOrderSummary
} from 'src/redux/slices/orderSummary';
import { restore as restorePreferences } from 'src/redux/slices/preferences';
import {
  restore as restorePurchaseSummary,
  clear as clearPurchaseSummary
} from 'src/redux/slices/purchaseSummary';
import {
  restore as restoreSalesSummary,
  clear as clearSalesSummary
} from 'src/redux/slices/salesSummary';
import { restore as restoreSC, clear as clearSC } from 'src/redux/slices/sc';
import { restore as restoreSL, clear as clearSL } from 'src/redux/slices/sl';
import { restore as restoreSL2, clear as clearSL2 } from 'src/redux/slices/sl2';
import {
  restore as restoreWFAdmin,
  clear as clearWFAd
} from 'src/redux/slices/wfAdmin';
import {
  restore as restoreDashboard,
  clear as clearDashboard
} from 'src/redux/slices/dashboard';
import {
  restore as restoreStoredValues,
  clear as clearStoredValues
} from 'src/redux/slices/storedValues';
import {
  restore as restoreSLSubBalances,
  clear as clearSubBalances
} from 'src/redux/slices/slSubBalances';
import {
  restore as restorePrivileges,
  clear as clearPriviledges
} from 'src/redux/slices/privileges';
import { restore as restoreFS, clear as clearFS } from 'src/redux/slices/fs';
import {
  restore as restoreCashPosition,
  clear as clearCashPosition
} from 'src/redux/slices/cashPosition';
import {
  restore as restoreMatureChecks,
  clear as clearMatureChecks
} from 'src/redux/slices/maturedChecks';
import {
  restore as restoreAging,
  clear as clearAging
} from 'src/redux/slices/aging';
import {
  restore as restoreJEptsReducer,
  clear as clearJEpts
} from 'src/redux/slices/jePts';
import {
  restore as restoreJEptsSummaryReducer,
  clear as clearJEptsSummary
} from 'src/redux/slices/jePtsSummary';

import {
  restore as restoreTransactions,
  clear as clearTransactions
} from 'src/redux/slices/transactions';
import { clear as clearBizMeta } from 'src/redux/slices/biz';
import {
  restore as restoreImbal,
  clear as clearImbal
} from 'src/redux/slices/imbal';
import { restore as restoreSOP, clear as clearSOP } from 'src/redux/slices/sop';
import { restore as restoreSOS, clear as clearSOS } from 'src/redux/slices/sos';
import {
  restore as restoreSpej,
  clear as clearSpecialJournal
} from 'src/redux/slices/specialJournals';
import {
  restore as restoreWFAssigned,
  clear as clearWFAssigned
} from 'src/redux/slices/wfAssigned';
import {
  restore as restoreSetup,
  clear as clearSetup
} from 'src/redux/slices/setup';
import {
  restore as restoreBudgetSummary,
  clear as clearBudgetSummary
} from 'src/redux/slices/budgetSummary';
import {
  restore as restoreBudgetRAPAL,
  clear as clearBudgetRAPAL
} from 'src/redux/slices/budgetRAPAL';
import {
  restore as restoreBudgetRAOD,
  clear as clearBudgetRAOD
} from 'src/redux/slices/budgetRAOD';
import {
  restore as restoreBudgetLedger,
  clear as clearBudgetLedger
} from 'src/redux/slices/budgetLedger';
import {
  restore as restoreWfStats,
  clear as clearWFStats
} from 'src/redux/slices/wfStats';
import {
  restore as restoreEI,
  clear as clearEI
} from 'src/redux/slices/endingInventory';
import {
  clear as clearCashierLogs,
  restore as restoreCashierLogs
} from 'src/redux/slices/cashierLogs';
import {
  clear as clearPrAnnualSummary,
  restore as restorePrAnnualSummary
} from 'src/redux/slices/prAnnualSummary';
import {
  clear as clear13MonthPay,
  restore as restore13MonthPay
} from 'src/redux/slices/trtnMonthPay';
import { clear as clearGJ, restore as restoreGJ } from 'src/redux/slices/gj';
import {
  clear as clearSAAO,
  restore as restoreSAAO
} from 'src/redux/slices/saao';
import {
  clear as clearWHTransfer,
  restore as restoreWHTransfer
} from 'src/redux/slices/whTransfer';
import {
  clear as clearCheckRegister,
  restore as restoreCheckRegister
} from 'src/redux/slices/checkRegister';

import { EXCLUDED_REDUCERS } from 'src/constants';

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(key))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

function useBizState() {
  const dispatch = useDispatch();

  const state = useSelector(state => state);
  const bizStates = useSelector(state => state.bizState);
  const bizMeta = useSelector(state => state.bizMeta);

  const sandbox = state?.auth?.sandbox || null;

  function restore(biz_uuid = '') {
    const bizState = bizStates?.[biz_uuid] || {};

    dispatch(restoreCustomReport(bizState?.customReport));
    dispatch(restoreDaloy(bizState?.daloy));
    dispatch(restoreGL(bizState?.gl));
    dispatch(restoreOrderSummary(bizState?.orderSummary));
    dispatch(restorePreferences(bizState?.preferences));
    dispatch(restorePurchaseSummary(bizState?.purchaseSummary));
    dispatch(restoreSalesSummary(bizState?.salesSummary));
    dispatch(restoreSC(bizState?.sc));
    dispatch(restoreSL(bizState?.sl));
    dispatch(restoreSL2(bizState?.sl2));
    dispatch(restoreWFAdmin(bizState?.wfAdmin));
    dispatch(restoreDashboard(bizState?.dashboard));
    dispatch(restoreStoredValues(bizState?.storedValues));
    dispatch(restoreSLSubBalances(bizState?.slSubBalances));
    dispatch(restorePrivileges(bizState?.privileges));
    dispatch(restoreFS(bizState?.fs));
    dispatch(restoreCashPosition(bizState?.cashPosition));
    dispatch(restoreMatureChecks(bizState?.matureChecks));
    dispatch(restoreAging(bizState?.aging));
    dispatch(restoreJEptsReducer(bizState?.jepts));
    dispatch(restoreJEptsSummaryReducer(bizState?.jeptssummary));
    dispatch(restoreSOS(bizState?.sos));
    dispatch(restoreSOP(bizState?.sop));
    dispatch(restoreTransactions(bizState?.transactions));
    dispatch(restoreImbal(bizState?.imbal));
    dispatch(restoreSpej(bizState?.specialJournal));
    dispatch(restoreWFAssigned(bizState?.wfAssigned));
    dispatch(restoreSetup(bizState?.setup));
    dispatch(restoreBudgetSummary(bizState?.budgetSummary));
    dispatch(restoreBudgetRAOD(bizState?.budgetRAOD));
    dispatch(restoreBudgetRAPAL(bizState?.budgetRAPAL));
    dispatch(restoreBudgetLedger(bizState?.budgetLedger));
    dispatch(restoreWfStats(bizState?.wfStats));
    dispatch(restoreEI(bizState?.endingInventory));
    dispatch(restoreCashierLogs(bizState?.cashierLogs));
    dispatch(restorePrAnnualSummary(bizState?.prAnnualSummary));
    dispatch(restore13MonthPay(bizState?.trtnMonthPay));
    dispatch(restoreGJ(bizState?.gj));
    dispatch(restoreSAAO(bizState?.saao));
    dispatch(restoreWHTransfer(bizState?.whTransfer));
    dispatch(restoreCheckRegister(bizState?.restoreCheckRegister));
  }

  function save() {
    let biz_uuid = bizMeta?.info?.biz_uuid;
    if (biz_uuid) {
      if (sandbox?.id) biz_uuid = biz_uuid + '-' + sandbox.id;

      const data = Object.filter(
        state,
        key => !EXCLUDED_REDUCERS.includes(key)
      );
      dispatch(saveBizState({ biz_uuid, data }));
    }
  }

  function clear() {
    dispatch(clearCashierLogs());
    dispatch(clearCustomReport());
    dispatch(clearDaloy());
    dispatch(clearGL());
    dispatch(clearOrderSummary());
    dispatch(clearPurchaseSummary());
    dispatch(clearSalesSummary());
    dispatch(clearSC());
    dispatch(clearSL());
    dispatch(clearSL2());
    dispatch(clearWFAd());
    dispatch(clearDashboard());
    dispatch(clearStoredValues());
    dispatch(clearSubBalances());
    dispatch(clearPriviledges());
    dispatch(clearFS());
    dispatch(clearCashPosition());
    dispatch(clearAging());
    dispatch(clearMatureChecks());
    dispatch(clearJEpts());
    dispatch(clearJEptsSummary());
    dispatch(clearSOP());
    dispatch(clearSOS());
    dispatch(clearSpecialJournal());
    dispatch(clearTransactions());
    dispatch(clearBizMeta());
    dispatch(clearImbal());
    dispatch(clearWFAssigned());
    dispatch(clearSetup());
    dispatch(clearBudgetSummary());
    dispatch(clearBudgetRAOD());
    dispatch(clearBudgetRAPAL());
    dispatch(clearBudgetLedger());
    dispatch(clearWFStats());
    dispatch(clearEI());
    dispatch(clearPrAnnualSummary());
    dispatch(clear13MonthPay());
    dispatch(clearGJ());
    dispatch(clearSAAO());
    dispatch(clearWHTransfer());
    dispatch(clearCheckRegister());
  }

  return { restore, save, clear };
}

export default useBizState;
