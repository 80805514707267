import React, { useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const ErrorsInItems = ({ errors }) => {
  const flds = Object.entries(errors?.flds ?? {})
    .map(([key, value]) => ({
      key,
      hidden: value?.hidden ?? false,
      label: value?.label ?? ''
    }))
    .filter(item => !item.hidden);

  return (
    <Box ml={1} py={2}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {flds.map(item => (
                <TableCell key={item.label}>{item.label}</TableCell>
              ))}
              {Boolean(errors?.flds?.['jid']) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.items.map(item => (
              <TableRow item={item.jid}>
                {flds.map(fld => (
                  <TableCell key={fld}>{item?.[fld.key]}</TableCell>
                ))}
                {Boolean(item?.['jid']) && (
                  <TableCell align="right">
                    <Tooltip title="Open in New Tab">
                      <IconButton
                        size="small"
                        onClick={() => {
                          window.open(`/app/trans/${item.jid}`, '_blank');
                        }}
                      >
                        <OpenInNewIcon fontSize="inherit" color="primary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const ErrorsInArray = ({ errors }) => {
  return (
    <Box ml={3}>
      <ul>
        {errors.map((item, i) => (
          <li key={i}>
            <Typography variant="caption" display="block">
              {Object.entries(item)
                .map(([key, value]) => value)
                .join(' - ')}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export const ErrorDetails = ({ errors }) => {
  if (!errors) return null;

  if (errors?.items && Array.isArray(errors?.items)) {
    return <ErrorsInItems errors={errors} />;
  }

  if (Array.isArray(errors)) {
    return <ErrorsInArray errors={errors} />;
  }

  return null;
};

export const ErrorItem = ({ data }) => {
  const [viewDetails, setViewDetails] = useState(false);

  return (
    <Box>
      <Link
        component="button"
        onClick={() => setViewDetails(prev => !prev)}
        variant="body2"
        color="textPrimary"
        style={{
          fontWeight: 900,
          textAlign: 'left',
          cursor: 'pointer',
          display: 'flex',
          gap: '.5em',
          alignItems: 'center'
        }}
        underline="none"
      >
        <span> {data?.msg ?? ''} </span>{' '}
        {Boolean(data?.ob) && (
          <span>
            {viewDetails ? (
              <ExpandLessIcon fontSize="inherit" />
            ) : (
              <ExpandMoreIcon fontSize="inherit" />
            )}
          </span>
        )}
      </Link>

      {viewDetails && <ErrorDetails errors={data?.ob} />}
    </Box>
  );
};

export const FormViewError = ({
  errorList = [],
  containerProps = {
    my: 1,
    width: '100%'
  },
  ...rest
}) => {
  if (errorList.length === 0) return null;

  return (
    <Box {...containerProps}>
      <Alert severity="error" {...rest}>
        <AlertTitle>Error</AlertTitle>
        {errorList.map((data, i) => (
          <ErrorItem data={data} key={i} />
        ))}
      </Alert>
    </Box>
  );
};
