import React, { useState } from 'react';
import { Schedule } from '@material-ui/icons';
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';

const TimeField = ({ data, onChangeField }) => {
  const { fld: name = '', caption = '', value = null } = data;
  const dateToday = moment().format('YYYY-MM-DD');

  const [error, setError] = useState('');

  function updateTime(value) {
    onChangeField({
      target: { value, name }
    });
  }

  /**
   *
   * @param {moment.Moment} date
   * @param {String} value
   */
  function handleChangeTime(date) {
    if (!date || !date.isValid()) {
      setError('Invalid time');
      updateTime(null);
      return;
    }

    updateTime(date.format('HH:mm'));
    setError('');
  }

  return (
    <KeyboardTimePicker
      fullWidth
      label={caption}
      value={value ? `${dateToday}T${value}:00` : null}
      onChange={handleChangeTime}
      inputVariant="outlined"
      keyboardIcon={<Schedule />}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default TimeField;
