import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'src/mixins/chartjs';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import 'src/assets/css/ibfrs.css';
import 'leaflet/dist/leaflet.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import {
  PreferencesProvider,
  ConfettiProvider,
  EnvProvider,
  AuthProvider
} from './contexts';
import { PageRefsProvider } from './contexts/PageRefsContext';

enableES5();

ReactDOM.render(
  // <React.StrictMode>
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <PreferencesProvider>
          <EnvProvider>
            <ConfettiProvider>
              <BrowserRouter>
                <PageRefsProvider>
                  <App />
                </PageRefsProvider>
              </BrowserRouter>
            </ConfettiProvider>
          </EnvProvider>
        </PreferencesProvider>
      </AuthProvider>
    </PersistGate>
  </ReduxProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
