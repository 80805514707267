function insertInEndOfPage(
  arrayToBeInserted = [],
  pageSize = 0,
  itemToInsert,
  generateId = false
) {
  const res = [];

  let add = 2;

  arrayToBeInserted.forEach((item, index) => {
    res.push(item);

    if ((index + add) % pageSize === 0) {
      res.push(
        generateId ? { ...itemToInsert, id: 'id-' + (add - 1) } : itemToInsert
      );
      add++;
    }
  });

  if (res.length % pageSize !== 0)
    res.push(
      generateId ? { ...itemToInsert, id: 'id-' + (add - 1) } : itemToInsert
    );

  return res;
}

export default insertInEndOfPage;
