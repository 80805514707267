import React from 'react';
import { Box } from '@material-ui/core';
import SrchProd from '../SrchProd';
import Number from './Number';

const Product = ({ data, onChangeField }) => {
  console.log(data);

  function handleChangeField({
    ixProd,
    sProd,
    ProdCd,
    cCost,
    cPrice1,
    cPrice2,
    cPrice3,
    cPrice4,
    cPrice5,
    ixCat,
    ixCatSub,
    sProdCat
  }) {
    if (data?.qty?.show) {
      onChangeField({
        target: {
          name: data.field,
          value: {
            ixProd,
            sProd,
            qty: data?.value?.qty ?? 0
          }
        }
      });
      return;
    }

    onChangeField({
      target: {
        name: data.field,
        value: {
          ixProd,
          sProd,
          ProdCd,
          cCost,
          cPrice1,
          cPrice2,
          cPrice3,
          cPrice4,
          cPrice5,
          ixCat,
          ixCatSub,
          sProdCat
        }
      }
    });
  }

  function onChangeQty(e) {
    const { value } = e.target;
    onChangeField({
      target: {
        name: data.field,
        value: {
          ixProd: data?.value?.ixProd ?? 0,
          sProd: data?.value?.sProd ?? 0,
          qty: +value
        }
      }
    });
  }

  if (Boolean(data?.qty?.show)) {
    return (
      <Box display="flex" gridGap="1em">
        <Box flex={1}>
          <SrchProd
            ixProd={data?.value?.ixProd || 0}
            sProd={data?.value?.sProd || ''}
            error={data.error}
            errorMessage={data.errorMessage}
            handleProdSelectRoot={handleChangeField}
            filters={{
              productCategories: data?.filters?.productCategories ?? []
            }}
            textBoxProps={data?.textBoxProps ?? {}}
            showPrice={data?.showPrice ?? false}
            priceProps={data?.priceProps ?? 'cPrice1'}
            showInactiveProd={data?.showInactiveProd ?? true}
          />
        </Box>
        <Number
          data={{
            value: data?.value?.qty ?? 0,
            caption: data?.qty?.caption ?? 'Qty',
            disabled: data?.qty?.disabled ?? false,
            rest: {
              style: {
                width: data?.qty?.width ?? 150
              }
            }
          }}
          onChangeField={onChangeQty}
        />
      </Box>
    );
  }

  return (
    <SrchProd
      ixProd={data?.value?.ixProd || 0}
      sProd={data?.value?.sProd || ''}
      error={data.error}
      errorMessage={data.errorMessage}
      handleProdSelectRoot={handleChangeField}
      filters={{ productCategories: data?.filters?.productCategories ?? [] }}
      textBoxProps={data?.textBoxProps ?? {}}
      showPrice={data?.showPrice ?? false}
      priceProps={data?.priceProps ?? 'cPrice1'}
      showInactiveProd={data?.showInactiveProd ?? true}
    />
  );
};

export default Product;
