import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  useTheme,
  Button,
  CircularProgress,
  Paper,
  LinearProgress
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import { getWFAssignedTransactions } from 'src/redux/slices/wfAssigned';
import getColorFromMUI from 'src/utils/getColorFromMUI';
import WFChart from './WFChart';
import TransCard from './TransCard';
import useJCdByUser from 'src/hooks/useJCdByUser';
import getixJCd from 'src/helpers/getixJCd';
import TableResult from './TableResult';
import EmptyBox from 'src/icons/Box.png';
import {
  setAssignedActiveCard,
  setAssignedLimit,
  setAssignedQuery,
  setAssignedSelectedTransChart,
  setAssignedSelectedWFChart,
  setHasAssignedTransactions
} from 'src/redux/slices/dashboard';
import { filterListByQuery } from 'src/helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  remarks: {
    width: '200px'
  },

  statuses: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    gap: theme.spacing(1)
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  },
  cursur: {
    cursor: 'pointer'
  },
  rowNewBorderColor: {
    borderLeft: `5px solid ${blue[500]}`,
    '& .MuiTableCell-root': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  rowBgColor: {
    '&:nth-child(even)': {
      backgroundColor: '#F2F2F2'
    }
  }
}));

const JVWFAssigned = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [transactionCounts, setTransactionCounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [activeStatus, setActiveStatus] = useState('');
  const { wf, brch } = useSelector(state => state.bizMeta);
  const { jv_wf_assigned, jv_wf_assigned_loading } = useSelector(
    state => state.wfAssigned
  );
  const { data: jcdList, error: jcdListError } = useJCdByUser();

  const {
    assignedTransactions: {
      activeCard,
      showUnseen,
      selectedTransChart,
      selectedWFChart,
      limit,
      query,
      ixBrch
    }
  } = useSelector(state => state.dashboard);

  const setActiveCard = JCd => {
    dispatch(setAssignedActiveCard(JCd));
  };

  const getTransactionCount = transactions => {
    if (!transactions) return [];

    if (transactions === null || transactions?.length === 0) return [];

    let savedTransactions = [];

    transactions
      .map(data => {
        return { JCd: data?.JCd };
      })
      .forEach(data => {
        const index = savedTransactions.findIndex(
          item => item.JCd === data.JCd
        );

        if (index > -1) {
          savedTransactions[index] = {
            ...savedTransactions[index],
            count: savedTransactions[index].count + 1
          };

          return;
        }

        savedTransactions.push({
          ...data,
          count: 1,
          active: data.JCd === activeCard
        });
      });

    if (savedTransactions.length === 1) {
      savedTransactions[0].active = true;
    }

    return savedTransactions;
  };

  const filterTransactions = transType => {
    setTransactionCounts(prev => {
      return prev.map(data => {
        return {
          ...data,
          active:
            data.active && transactionCounts.length !== 1
              ? false
              : transType === data.JCd
        };
      });
    });

    if (transType === activeCard) {
      if (transactionCounts.length === 1) {
        setActiveCard(transType);
      } else {
        setActiveCard('');
      }
    } else {
      setActiveCard(transType);
    }

    setPage(0);
    setActiveStatus('');
  };

  const handleLimitChange = value => {
    dispatch(setAssignedLimit(value));
    setPage(0);
  };

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const filteredTransactions = ({ transactions }) =>
    !transactions || transactions === null || transactions.length === 0
      ? []
      : filterListByQuery(
          query,
          transactions.filter(
            data =>
              !(
                (activeCard !== '' && data.JCd !== activeCard) ||
                (activeStatus !== '' && +data?.wfStatus !== +activeStatus)
              )
          ),
          ['JCd', 'Name', 'RefNo', 'jid']
        )
          .filter(rows => (!showUnseen ? true : !rows?.lastview))
          .filter(rows =>
            ixBrch === 0 || (brch?.mode ?? 0) <= 0
              ? true
              : rows.ixBrch === ixBrch
          )
          .sort((l, r) => {
            return new Date(l.jDate).getTime() > new Date(r.jDate).getTime()
              ? -1
              : 1;
          });

  const getTransactionStatuses = () => {
    if (!transactions || transactions.length === 0) return [];

    if (activeCard === '') return [];

    const ixJCd = getixJCd(activeCard, wf);

    return Object.entries(wf?.[ixJCd]?.wf ?? {}).map(
      ([_, { ixStatus, sStatus, color }]) => ({
        ixStatus,
        sStatus,
        color,
        count: jv_wf_assigned.items.filter(
          ({ JCd, wfStatus }) => JCd === activeCard && wfStatus === +ixStatus
        ).length
      })
    );
  };

  const handleClickBarChartTransactions = data => {
    if (typeof data?._index === 'undefined') return;

    const keys = transactionCounts.map(transaction => transaction.JCd);
    const key = keys[data?._index];

    filterTransactions(key);
  };

  const handleClickBarChartWorkFlowStatus = data => {
    if (typeof data?._index === 'undefined') return;

    const keys = getTransactionStatuses()
      .filter(wf => wf.count !== 0)
      .map(wf => wf.ixStatus);

    const key = keys[data?._index];

    setActiveStatus(key);
  };

  const getJCdInfo = ({ JCd }) => {
    if (jcdListError !== '') return {};

    const index = jcdList.findIndex(item => item.JCd === JCd);

    if (index === -1) return {};

    return jcdList[index];
  };

  const handleChangeTransChart = type => {
    dispatch(setAssignedSelectedTransChart(type));
  };

  const handleChangeWFChart = type => {
    dispatch(setAssignedSelectedWFChart(type));
  };

  const handleChangeSearch = e => {
    setPage(0);
    dispatch(setAssignedQuery(e.target.value));
  };

  const handleChangeActiveWFStatus = value => {
    setActiveStatus(value);
  };

  const getCardIcon = JCd => {
    const transIndex = jcdList.findIndex(data => data.JCd === JCd);
    const ixJCd = getixJCd(JCd, wf);

    return (
      wf?.[ixJCd]?.['wf-settings']?.icon ?? jcdList?.[transIndex]?.icon ?? ''
    );
  };

  const getCardColor = JCd => {
    const ixJCd = getixJCd(JCd, wf);
    return wf?.[ixJCd]?.['wf-settings']?.color ?? 'default';
  };

  const getCardShade = JCd => {
    const ixJCd = getixJCd(JCd, wf);
    return wf?.[ixJCd]?.['wf-settings']?.shade ?? 500;
  };

  const getTranCardDescription = JCd => {
    const transIndex = jcdList.findIndex(data => data.JCd === JCd);

    return jcdList?.[transIndex]?.sJCd ?? JCd;
  };

  // WF Charts
  const WFTransCount = transactionCounts.map(transaction => transaction.count);
  const WFTransLabels = transactionCounts.map(transaction =>
    getTranCardDescription(transaction.JCd)
  );
  const WFTransBackgroundColor = transactionCounts.map(data =>
    getColorFromMUI({
      color: getCardColor(data.JCd),
      shade: getCardShade(data.JCd)
    })
  );

  const filteredTransStatuses = getTransactionStatuses().filter(
    wf => wf.count !== 0
  );
  const WFStatuses = filteredTransStatuses.map(wf => wf.count);
  const WFLabels = filteredTransStatuses.map(wf => wf.sStatus);
  const WFBackgroundColor = filteredTransStatuses.map(data =>
    getColorFromMUI({ color: data.color })
  );

  useEffect(() => {
    dispatch(getWFAssignedTransactions());
  }, []);

  useEffect(() => {
    const jvTransactions = getTransactionCount(jv_wf_assigned?.items ?? []);
    if (jvTransactions.length === 1) setActiveCard(jvTransactions?.[0]?.JCd);

    setTransactionCounts(jvTransactions);
    setTransactions(jv_wf_assigned?.items ?? []);

    dispatch(
      setHasAssignedTransactions((jv_wf_assigned?.items ?? []).length !== 0)
    );
  }, [jv_wf_assigned]);

  if (jv_wf_assigned_loading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={500}
        p={3}
      >
        <Box
          width={{ xs: '100%', sm: '100%', md: '50%', lg: '30%', xl: '30%' }}
          p={3}
        >
          <Typography variant="body2" color="primary" align="center">
            Please Wait
          </Typography>
          <Paper evevation={1} component={Box} mt={1} p={1}>
            <LinearProgress />
          </Paper>
        </Box>
      </Box>
    );

  if (transactionCounts.length <= 0)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={15}
      >
        <img src={EmptyBox} alt="Empty" width={150} />
        <Typography
          variant="h4"
          color="primary"
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
          }}
        >
          No transactions currently assigned to you.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            dispatch(getWFAssignedTransactions());
          }}
        >
          refresh
        </Button>
      </Box>
    );

  return (
    <>
      <Box mt={3}>
        <Grid container spacing={3}>
          {transactionCounts.map((data, i) => (
            <Grid item key={i} xs={6} sm={6} md={6} lg={3} xl={3}>
              <TransCard
                filterTransactions={filterTransactions}
                color={getCardColor(data.JCd)}
                shade={getCardShade(data.JCd)}
                icon={getCardIcon(data.JCd)}
                sJCd={getTranCardDescription(data.JCd)}
                {...data}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className={classes.graphContainer} my={3}>
        <WFChart
          data={WFTransCount}
          labels={WFTransLabels}
          backgroundColor={WFTransBackgroundColor}
          title={`Transactions (${transactions.length})`}
          callBack={handleClickBarChartTransactions}
          onChangeChartType={handleChangeTransChart}
          defaultChartType={selectedTransChart}
        />
        <WFChart
          data={WFStatuses}
          labels={WFLabels}
          backgroundColor={WFBackgroundColor}
          title="Transaction Work Flow Status"
          callBack={handleClickBarChartWorkFlowStatus}
          onChangeChartType={handleChangeWFChart}
          defaultChartType={selectedWFChart}
        />
      </Box>
      <TableResult
        page={page}
        limit={limit}
        query={query}
        activeCard={activeCard}
        activeStatus={activeStatus}
        jcdListError={jcdListError}
        loading={jv_wf_assigned_loading}
        transactionStatuses={filteredTransStatuses}
        jcdInfo={getJCdInfo({ JCd: activeCard })}
        refresh={() => {
          dispatch(getWFAssignedTransactions());
        }}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
        onChangeSearch={handleChangeSearch}
        onChangeActiveWFStatus={handleChangeActiveWFStatus}
        transactions={filteredTransactions({ transactions })}
      />
    </>
  );
};

export default JVWFAssigned;
