import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import OtpInput from 'react-otp-input';
import Alert from '@material-ui/lab/Alert';
import { useToggle } from 'src/hooks';
import { InputAdornment, IconButton } from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';

const inputStyle = {
  fontSize: '1.3rem',
  fontWeight: 600,
  width: '2rem',
  height: '2rem'
};

const containerStyle = {
  display: 'flex',
  gap: '1em',
  justifyContent: 'space-evenly',
  marginTop: '1em',
  marginBottom: '1em'
};

const OTP = ({
  requestSuccessResult,
  otpFailedResult,
  resendOTP,
  sendOTP,
  loading,
  timer,
  onChangeTimer,
  resendFailed,
  isResending,
  valueType,
  value
}) => {
  const [otp, setOtp] = useState('');

  const [passwordVisible, , , togglePasswordVisibility] = useToggle();
  const [pwConfirmVisible, , , togglePwConfirmVisibility] = useToggle();

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        onChangeTimer(+timer - 1);
      }, 1000);
    }
  }, [timer]);

  const onChangeOTP = val => {
    setOtp(val);
  };

  const handleResendOtp = () => {
    resendOTP({
      type: valueType,
      value
    });
  };

  return (
    <Box>
      {otpFailedResult && <Alert severity="error">{otpFailedResult}</Alert>}
      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .max(125)
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .test(
              'hasUpperCase',
              'Password must contain at least 1 uppercase letter',
              value => /(?=.*[A-Z])/.test(value)
            )
            .test(
              'hasLowerCase',
              'Password must contain at least 1 lowercase letter',
              value => /(?=.*[a-z])/.test(value)
            )
            .test(
              'hasNumber',
              'Password must contain at east 1 number',
              value => /[0-9]/.test(value)
            )
            .test(
              'hasSpecialChar',
              'Password must contain at least 1 special character.',
              value => /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(value)
            ),
          confirmPassword: Yup.string()
            .max(125)
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords did not match')
        })}
        onSubmit={values => {
          if (otp.length < 6) return;
          sendOTP({ password: values.password, OTP: otp });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                type={passwordVisible ? 'text' : 'password'}
                variant="outlined"
                label="New password"
                fullWidth
                margin="normal"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        size="small"
                        tabIndex={1}
                        disabled={loading}
                      >
                        {passwordVisible ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                type={pwConfirmVisible ? 'text' : 'password'}
                variant="outlined"
                label="Confirm password"
                fullWidth
                margin="normal"
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePwConfirmVisibility}
                        size="small"
                        tabIndex={1}
                        disabled={loading}
                      >
                        {pwConfirmVisible ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Divider />
            <Box my={2}>
              <Typography variant="h4" color="textSecondary" align="center">
                OTP Verification
              </Typography>

              {requestSuccessResult?.messages && (
                <Typography variant="body2" color="primary" align="center">
                  {requestSuccessResult?.messages.map(({ msg }) => msg).join()}
                </Typography>
              )}

              <OtpInput
                value={otp}
                onChange={onChangeOTP}
                containerStyle={containerStyle}
                inputStyle={inputStyle}
                numInputs={6}
                separator=""
                isInputNum
                shouldAutoFocus
              />

              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Didn't you receive any code?
                </Typography>

                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={handleResendOtp}
                  disabled={timer > 0 || loading || isResending}
                >
                  {timer > 0 ? `Resend (${timer})` : 'Resend'}
                </Button>
              </Box>
              {resendFailed && (
                <Box my={1}>
                  <Typography
                    variant="caption"
                    display="block"
                    color="error"
                    align="center"
                  >
                    {resendFailed}
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider />
            <Box mt={3}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={otp.length < 6 || loading}
              >
                {loading ? 'Please wait...' : 'Reset password'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default OTP;
