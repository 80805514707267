import React, { useState, useEffect } from 'react';
import { Box, Paper, Button, Typography } from '@material-ui/core';
import OtpInput from 'react-otp-input';

const inputStyle = {
  fontSize: '1.3rem',
  fontWeight: 600,
  width: '2rem',
  height: '2rem'
};

const containerStyle = {
  display: 'flex',
  gap: '1em',
  justifyContent: 'space-evenly',
  marginTop: '1em',
  marginBottom: '1em'
};

const OTP = ({
  saveSuccess,
  activateCode,
  resendOTP,
  timer,
  isResending,
  resendFailed,
  onChangeTimer,
  activationFailed,
  isActivating
}) => {
  const [otpCode, setOtpCode] = useState('');

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        onChangeTimer(+timer - 1);
      }, 1000);
    }
  }, [timer]);

  const onChangeOTP = code => {
    setOtpCode(code);
  };

  const handleResendOtp = () => {
    resendOTP({ username: saveSuccess?.user?.username });
  };

  const handleSendOtp = () => {
    activateCode(otpCode);
  };

  return (
    <>
      <Box my={3}>
        <Typography color="primary" variant="h4" align="center">
          {saveSuccess?.msg}
        </Typography>
      </Box>

      <Paper component={Box} px={2} pb={2} my={3}>
        <Box pt={2}>
          <Typography variant="h4" align="center">
            OTP Verification
          </Typography>

          <OtpInput
            value={otpCode}
            onChange={onChangeOTP}
            containerStyle={containerStyle}
            inputStyle={inputStyle}
            numInputs={6}
            separator=""
            isInputNum
            shouldAutoFocus
          />

          {activationFailed && (
            <Box my={1}>
              <Typography
                variant="caption"
                display="block"
                color="error"
                align="center"
              >
                {activationFailed}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" color="textSecondary" align="center">
              Didn't you receive any code?
            </Typography>

            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={handleResendOtp}
              disabled={timer > 0 || isResending}
            >
              {timer > 0 ? `Resend (${timer})` : 'Resend'}
            </Button>
          </Box>
          {resendFailed && (
            <Box my={1}>
              <Typography
                variant="caption"
                display="block"
                color="error"
                align="center"
              >
                {resendFailed}
              </Typography>
            </Box>
          )}
        </Box>

        <Box mt={3}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={otpCode.length < 6 || isActivating}
            onClick={handleSendOtp}
          >
            Activate Account
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default OTP;
