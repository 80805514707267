import React from 'react';
import SrchAcc from '../SrchAcc';

const Account = ({ data, onChangeField }) => {
  const handleChangeAccount = ({ ixAcc, sAccTitle }) => {
    onChangeField({
      target: {
        name: data.fld,
        value: {
          ixAcc,
          sAccTitle
        }
      }
    });
  };

  return (
    <SrchAcc
      selectedAcc={{
        id: data?.value?.ixAcc ?? 0,
        title: data?.value?.sAccTitle ?? ''
      }}
      onSelectAccount={handleChangeAccount}
      error={data?.error ?? false}
      errorMessage={data?.errorMessage ?? ''}
      allowSelectParent={false}
      {...(data?.rest ?? {})}
    />
  );
};

export default Account;
