import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedProd: { id: 0, title: '', ixCat: 0 },
  coilNumber: '',
  warehouse: '',
  warehouseList: [],
  data: null,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  subWarehouse: {
    show: false,
    ixSubAcc: 0,
    sSubAcc: '',
    ixSubType: 0,
    sSubType: ''
  },
  showPrice: false,
  showOrders: false,
  page: 0,
  limit: 25,
  loading: false,
  error: false,
  errorMsg: '',
  graphData: {},
  hasSN: false,
  hasSN2: false,
  SN: '',
  SN2: '',
  showCSSC: false,
  showCompact: true,
  graphRequestData: {},
  graphJCDList: [],
  showGraphBreakdown: false
};

const slice = createSlice({
  name: 'sc',
  initialState: INITIAL_STATE,
  reducers: {
    setReportState(state, action) {
      return { ...state, ...action.payload, loading: false };
    },
    resetState(state) {
      return {
        ...INITIAL_STATE,
        warehouse: state.warehouse,
        warehouseList: state.warehouseList,
        subWarehouse: state.subWarehouse,
        brch: { ...state.brch, ixBrch: 0 }
      };
    },
    setDateFilter(state, action) {
      state.dateFilter = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setProduct(state, action) {
      state.selectedProd = action.payload;
    },
    setError(state, action) {
      return { ...state, ...action.payload };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
      state.page = 0;
    },
    setCoilNo(state, action) {
      state.coilNumber = action.payload;
    },
    setWarehouse(state, action) {
      state.warehouse = action.payload;
    },
    setWarehouseList(state, action) {
      state.warehouseList = action.payload;
    },
    setBrch(state, action) {
      state.brch = {
        ...state.brch,
        ixBrch: action.payload
      };
    },
    setBrchRequired(state, action) {
      state.brch = {
        ...state.brch,
        label: action.payload.label,
        required: action.payload.required
      };
    },
    cancelRequest(state) {
      state.loading = false;
    },
    togglePrice(state, action) {
      state.showPrice = action.payload;
    },
    toggleOrders(state, action) {
      state.showOrders = action.payload;
    },
    setGraphData(state, action) {
      state.graphData = action.payload;
    },
    setRequestData(state, action) {
      state.graphRequestData = action.payload;
    },
    setSubWarehouse(state, action) {
      state.subWarehouse = action.payload;
    },
    setHasSN(state, action) {
      state.hasSN = action.payload.hasSN;
      state.hasSN2 = action.payload.hasSN2;
    },
    setSN(state, action) {
      state.SN = action.payload;
    },
    setSN2(state, action) {
      state.SN2 = action.payload;
    },
    setJCdList(state, action) {
      state.graphJCDList = action.payload;
    },
    setShowGraphBreakdown(state, action) {
      state.showGraphBreakdown = action.payload;
    },
    toggleShowCompact(state, action) {
      state.showCompact = action.payload;
    },
    setParams(state, action) {
      state.selectedProd.id = action.payload.ixProd;
      state.selectedProd.title = action.payload.sProd;

      state.brch.ixBrch = action.payload.ixBrch;
      state.warehouse = action.payload.ixWH;

      state.dateRange.dt1 = action?.payload?.dt1 ?? state.dateRange.dt1;
      state.dateRange.dt2 = action?.payload?.dt2 ?? state.dateRange.dt2;
      state.dateRange.textValue =
        action?.payload?.sDate ?? state.dateRange.textValue;

      if (action?.payload?.SN) {
        state.SN = action?.payload?.SN;
        state.selectedProd.reqSN = 1;
        state.hasSN = true;
      } else {
        state.SN = '';
        state.selectedProd.reqSN = 0;
        state.hasSN = false;
      }

      if (action?.payload?.SN2) {
        state.SN2 = action?.payload?.SN2;
        state.selectedProd.reqSN2 = 1;
        state.hasSN2 = true;
      } else {
        state.SN2 = '';
        state.selectedProd.reqSN2 = 0;
        state.hasSN2 = false;
      }
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const scReducer = slice.reducer;

export const {
  setReportState,
  resetState,
  setDateFilter,
  setDateRange,
  setProduct,
  setError,
  setLoading,
  setPage,
  setLimit,
  setCoilNo,
  setWarehouse,
  setWarehouseList,
  setBrch,
  setBrchRequired,
  cancelRequest,
  toggleOrders,
  togglePrice,
  setGraphData,
  setRequestData,
  setSubWarehouse,
  setHasSN,
  setSN,
  setSN2,
  setJCdList,
  setShowGraphBreakdown,
  clear,
  restore,
  setParams,
  toggleShowCompact
} = slice.actions;
export default scReducer;
