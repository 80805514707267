import { Log } from 'src/views/trans/Payroll/models';
import { formatTimeToStr } from 'src/utils';

/**
 * @param {Log[]} timeLogs
 */
function generateTimeLogsData(timeLogs) {
  const res = [];

  const totals = {
    vOT: 0,
    vOT_RH: 0,
    vOT_SH: 0,
    vOT_ND: 0,
    vOT_ND_RH: 0,
    vOT_ND_SH: 0,
    vOT_RD: 0,
    vOT_RD_OT: 0,
    vOT_RD_RH: 0,
    vOT_RD_SH: 0,

    vND: 0,
    vND_RH: 0,
    vND_SH: 0,
    vND_RD: 0,
    vND_RD_RH: 0,
    vND_RD_SH: 0,

    vPresent: 0,
    vHrs: 0,
    vAbsent: 0,
    vLate: 0,
    vUT: 0,
    vOB: 0,
    vRH: 0,
    vSH: 0,
    vSL: 0,
    vVL: 0,
    vML: 0,
    vPL: 0
  };

  timeLogs.forEach(
    ({
      BIO1,
      BIO2,
      BIO3,
      BIO4,
      BIO5,
      BIO6,
      BIO7,
      BIO8,
      BIO9,
      BIO10,
      BIO11,
      BIO12,
      SYS_AM_IN,
      SYS_AM_OUT,
      SYS_PM_IN,
      SYS_PM_OUT,
      SYS_OT_IN,
      SYS_OT_OUT,
      HR_AM_IN,
      HR_AM_OUT,
      HR_PM_IN,
      HR_PM_OUT,
      HR_OT_IN,
      HR_OT_OUT,
      SHF1_HR_IN,
      SHF1_HR_OUT,
      SHF2_HR_IN,
      SHF2_HR_OUT,
      ...props
    }) => {
      res.push({
        ...props,
        BIO1: formatTimeToStr(BIO1),
        BIO2: formatTimeToStr(BIO2),
        BIO3: formatTimeToStr(BIO3),
        BIO4: formatTimeToStr(BIO4),
        BIO5: formatTimeToStr(BIO5),
        BIO6: formatTimeToStr(BIO6),
        BIO7: formatTimeToStr(BIO7),
        BIO8: formatTimeToStr(BIO8),
        BIO9: formatTimeToStr(BIO9),
        BIO10: formatTimeToStr(BIO10),
        BIO11: formatTimeToStr(BIO11),
        BIO12: formatTimeToStr(BIO12),
        SYS_AM_IN: formatTimeToStr(SYS_AM_IN),
        SYS_AM_OUT: formatTimeToStr(SYS_AM_OUT),
        SYS_PM_IN: formatTimeToStr(SYS_PM_IN),
        SYS_PM_OUT: formatTimeToStr(SYS_PM_OUT),
        SYS_OT_OUT: formatTimeToStr(SYS_OT_OUT),
        SYS_OT_OUT: formatTimeToStr(SYS_OT_OUT),
        EMP_AM_IN: formatTimeToStr(HR_AM_IN),
        EMP_AM_OUT: formatTimeToStr(HR_AM_OUT),
        EMP_PM_IN: formatTimeToStr(HR_PM_IN),
        EMP_PM_OUT: formatTimeToStr(HR_PM_OUT),
        EMP_OT_IN: formatTimeToStr(HR_OT_IN),
        EMP_OT_OUT: formatTimeToStr(HR_OT_OUT),
        HR_AM_IN: formatTimeToStr(HR_AM_IN),
        HR_AM_OUT: formatTimeToStr(HR_AM_OUT),
        HR_PM_IN: formatTimeToStr(HR_PM_IN),
        HR_PM_OUT: formatTimeToStr(HR_PM_OUT),
        HR_OT_IN: formatTimeToStr(HR_OT_IN),
        HR_OT_OUT: formatTimeToStr(HR_OT_OUT),
        SHF1_IN: formatTimeToStr(SHF1_HR_IN),
        SHF1_OUT: formatTimeToStr(SHF1_HR_OUT),
        SHF2_IN: formatTimeToStr(SHF2_HR_IN),
        SHF2_OUT: formatTimeToStr(SHF2_HR_OUT),
        SHF1_HR_IN: formatTimeToStr(SHF1_HR_IN),
        SHF1_HR_OUT: formatTimeToStr(SHF1_HR_OUT),
        SHF2_HR_IN: formatTimeToStr(SHF2_HR_IN),
        SHF2_HR_OUT: formatTimeToStr(SHF2_HR_OUT)
      });

      Object.keys(totals).forEach(key => {
        totals[key] += props?.[key] || 0;
      });
    }
  );

  res.push({
    ixDate: '',
    empBioID: '',
    BIO1: '',
    BIO2: '',
    BIO3: '',
    BIO4: '',
    BIO5: '',
    BIO6: '',
    BIO7: '',
    BIO8: '',
    BIO9: '',
    BIO10: '',
    BIO11: '',
    BIO12: '',
    SYS_AM_IN: '',
    SYS_AM_OUT: '',
    SYS_PM_IN: '',
    SYS_PM_OUT: '',
    SYS_OT_IN: '',
    SYS_OT_OUT: '',
    HR_AM_IN: '',
    HR_AM_OUT: '',
    HR_PM_IN: '',
    HR_PM_OUT: '',
    HR_OT_IN: '',
    HR_OT_OUT: '',
    ...totals
  });

  return res;
}

export default generateTimeLogsData;
