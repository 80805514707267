import React from 'react';
import {
  Avatar,
  AppBar,
  Toolbar,
  makeStyles,
  Link,
  Box,
  Container
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { orange } from '@material-ui/core/colors';
import { useAuth, useEnv } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    height: 64
  },

  link: {
    fontWeight: theme.typography.fontWeightBold,
    '& + &': {
      marginLeft: theme.spacing(3)
    },
    '&:hover': {
      color: orange[300]
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  biz: {
    color: orange[500],
    fontWeight: 900
  },

  iconContainer: {
    display: 'flex',
    gap: '.3em',
    alignItems: 'center',
    filter: 'grayscale(10%)',
    transition: 'all 500ms ease-in-out',
    '&:hover': {
      filter: 'grayscale(0)'
    }
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { portal, app } = useAuth();
  const { CONFIG } = useEnv();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      elevation={0}
      {...rest}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.iconContainer}>
            <RouterLink to="/">
              <Avatar src={CONFIG.logo_url.small} />
            </RouterLink>
            <Link
              component={RouterLink}
              variant="h3"
              to="/"
              underline="none"
              color="textSecondary"
            >
              {CONFIG.name.first_word}
              <span className={classes.biz}>{CONFIG.name.second_word}</span>
            </Link>
          </Box>

          <Box flexGrow={1} />

          {portal && (
            <Link
              className={classes.link}
              component="button"
              onClick={() => navigate('/portal/welcome')}
              color="textSecondary"
              underline="none"
              variant="body1"
            >
              Portal
            </Link>
          )}

          {app && (
            <Link
              className={classes.link}
              component="button"
              onClick={() => navigate('/app/dashboard')}
              color="textSecondary"
              underline="none"
              variant="body1"
            >
              Dashboard
            </Link>
          )}

          <Link
            className={classes.link}
            component="button"
            onClick={() => {
              navigate('/contact-us');
            }}
            color="textSecondary"
            underline="none"
            variant="body1"
          >
            Contact Us
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
