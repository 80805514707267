import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#F8FBFB',
    position: 'relative',
    height: 'auto',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      height: '100%',
      backgroundSize: 'auto'
    },
    overflow: 'visible',
    '&:before': {
      content: '""',
      display: 'block',
      background: 'url(/static/images/landing-page/rocket.png) no-repeat',
      position: 'absolute',
      left: -200,
      top: -130,
      width: 1000,
      height: 1000,
      transform: 'rotate(45deg)',
      opacity: 0.1,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  },
  system: {
    '& .system-img': {
      background: 'transparent',
      height: 'auto',
      width: 170,
      transition: 'all 200ms ease-in-out'
    },
    '& .system-title': {
      fontSize: '1.8rem',
      textAlign: 'center',
      transition: 'all 200ms ease-in-out'
    },
    '& :hover': {
      '& .system-title': {
        transform: 'translateY(-10px)',
        filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.2))'
      },
      '& .system-img': {
        transform: 'translateY(-10px)',
        filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.2))'
      }
    }
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5em'
  },
  firstWord: {
    color: '#FF7704',
    fontWeight: 500
  },
  secondWord: {
    color: '#000',
    fontWeight: 900
  },

  paperComponent: {
    height: '100%',
    transition: 'all 250ms ease-in-out',
    '&:hover': {
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset',
      transform: 'translateY(-1em)'
    }
  },
  container: {
    position: 'relative',
    zIndex: 1
  },
  learnMore: {
    color: '#FF7704',
    marginLeft: 'auto'
  },
  description: {
    lineHeight: '1.8em',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  }
}));

const StandAloneSystem = ({ title, description, src, url, delay, reveal }) => {
  const classes = useStyles();

  return (
    <Animated
      to={{ opacity: reveal ? 1 : 0, x: reveal ? 0 : -50 }}
      config={{
        duration: 500
      }}
      delay={delay}
    >
      <Box height="100%">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.system}
        >
          <RouterLink to={url}>
            <img className="system-img" src={src} alt={title} />
            <Title firstWord={title.first} secondWord={title.second} />
          </RouterLink>
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.description}
        >
          {description}
        </Typography>

        <Button
          component={RouterLink}
          to={url}
          className={classes.learnMore}
          fullWidth
        >
          Learn More
        </Button>
      </Box>
    </Animated>
  );
};

const Title = ({ firstWord, secondWord }) => {
  const classes = useStyles();
  return (
    <Typography className="system-title">
      <span className={classes.firstWord}>{firstWord}</span>
      <span className={classes.secondWord}>{secondWord}</span>
    </Typography>
  );
};

const SYSTEMS = [
  {
    title: {
      first: 'Ulap',
      second: 'Tax'
    },
    description:
      'Designed with integrated Philippine tax features on income tax, business tax, withholding taxes, and mandatory compliance reports.',
    src: '/static/images/sub-systems/tax.png',
    url: '/beyond-accounting/tax'
  },
  {
    title: {
      first: 'Ulap',
      second: 'Inventory'
    },
    src: '/static/images/sub-systems/inv.png',
    description:
      'Helps your business track its inventory in quantity and cost to better manage cash flow projections and proper determination of handling costs.',
    url: '/beyond-accounting/inv'
  },
  {
    title: {
      first: 'Ulap',
      second: 'Targets'
    },
    src: '/static/images/sub-systems/targets.png',
    description:
      'Provide interface that shows your actual/projected data and graphical representations adaptable to relevant parameters.',
    url: '/beyond-accounting/targets'
  },
  {
    title: {
      first: 'Daloy',
      second: 'Workflow'
    },
    src: '/static/images/sub-systems/daloy.png',
    description:
      'Identifies the movement of process, assignments and approval of tasks ensuring the sight of effective internal controls.',
    url: '/beyond-accounting/daloy'
  },
  {
    title: {
      first: 'Ulap',
      second: 'Payroll'
    },
    src: '/static/images/sub-systems/payroll.png',
    description:
      'Manage your workforce compensation and maintain statutory compliance at the same time.',
    url: '/beyond-accounting/payroll'
  },
  {
    title: {
      first: 'Ulap',
      second: 'Portals'
    },
    src: '/static/images/sub-systems/portal.png',
    description:
      'Your all-in-one business portal for employee payroll access and client support case monitoring.',
    url: '/beyond-accounting/portal'
  }
];

const AccountingSoftware = ({ className, onGotoProductLine, ...rest }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg" className={classes.container}>
        <div ref={ref}>
          <Animated
            to={{ opacity: reveal ? 1 : 0, y: reveal ? 0 : -50 }}
            config={{
              duration: 1000
            }}
          >
            <Typography className={classes.mainTitle} align="center">
              Accounting and{' '}
              <span
                style={{
                  background: '#ff7704',
                  padding: '.1em .2em',
                  color: '#fff',
                  borderRadius: '10px'
                }}
              >
                Beyond <FontAwesomeIcon icon={faRocket} />
              </span>
            </Typography>
          </Animated>

          <Box className={classes.wrapper}>
            <Grid container spacing={5} justifyContent="center">
              {SYSTEMS.map((data, i) => (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={data.url}>
                  <StandAloneSystem delay={i * 200} reveal={reveal} {...data} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      </Container>
    </div>
  );
};

AccountingSoftware.propTypes = {
  className: PropTypes.string
};

export default AccountingSoftware;
