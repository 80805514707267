import React from 'react';
import {
  Box,
  IconButton,
  Button,
  Typography,
  Link,
  Tooltip,
  Avatar,
  makeStyles
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ArrowRight } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  emptyImg: {
    width: '100px',
    height: 'auto',
    marginBottom: theme.spacing(2)
  },
  longCell: {
    width: '350px'
  },
  ellipses: {
    lineClamp: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px'
  },
  cardActions: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textField: {
    padding: theme.spacing(1)
  },
  rowNewBorderColor: {
    borderLeft: `5px solid ${blue[500]}`,
    '& .MuiTableCell-root': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  rowBgColor: {
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

export const columns = [
  {
    field: 'refNo',
    headerName: 'Ref #',
    width: 150,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{row.RefNo}</strong> <br />
        {row.JCd}
      </Typography>
    )
  },
  {
    field: 'jDate',
    headerName: 'Date',
    width: 170,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{moment(row.jDate).format('MMM  DD, YYYY')}</strong>
        <br />
        {moment(row.jDate).fromNow()}
      </Typography>
    )
  },
  {
    field: 'Name',
    headerName: 'Name',
    flex: 1,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong>{row.Name}</strong> <br />
        {row.Particulars}
      </Typography>
    )
  },

  {
    field: 'comment',
    headerName: 'Comments',
    flex: 1,
    renderCell: ({ row }) => (
      <Box display="flex" gridGap=".5em">
        <Avatar src={`/api/images/user/${row.username}/avatar.jpg`}>
          {row?.username[0].toUpperCase()}
        </Avatar>
        <Typography variant="body2">
          <strong>
            {row.username.charAt(0).toUpperCase() + row.username.slice(1)}
          </strong>
          <br />
          {row.comment}
        </Typography>
      </Box>
    )
  },
  {
    field: 'activityDate',
    headerName: 'Activity Date',
    width: 170,
    renderCell: ({ row }) => (
      <Typography variant="body2">
        <strong> {moment(row.ts).format('MMM  DD, YYYY')} </strong> <br />
        {moment(row.ts).fromNow()}
      </Typography>
    )
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    renderCell: ({ row }) => (
      <Box>
        <Tooltip title="Open here">
          <IconButton component={Link} href={'/app/trans/' + row.jid}>
            <ArrowRight fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open in New Tab">
          <IconButton
            component={Link}
            href={'/app/trans/' + row.jid}
            target="_blank"
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
];

const EmptyState = ({ refresh, loading }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={3}
    >
      <img
        src="/static/images/empty-state/Box.png"
        className={classes.emptyImg}
      />
      <Typography variant="h4" align="center" color="textSecondary">
        No comments found
      </Typography>
      <Button
        color="primary"
        variant="text"
        onClick={refresh}
        disabled={loading}
      >
        {loading ? 'Please wait...' : 'Try again'}
      </Button>
    </Box>
  );
};

const Comments = ({
  comments = [],
  loading = false,
  pageSize = 5,
  refresh
}) => {
  const classes = useStyles();

  const getRowLeftBorder = isNew => (isNew ? classes.rowNewBorderColor : '');

  if (comments.length === 0)
    return <EmptyState refresh={refresh} loading={loading} />;

  return (
    <DataGrid
      disableColumnMenu
      disableColumnFilter
      style={{ border: 'none' }}
      rows={comments.map(data => ({
        ...data,
        id: `${data.jid}-${Math.floor(Math.random() * 1000)}`
      }))}
      getRowClassName={params => {
        return getRowLeftBorder(params.row.isNew);
      }}
      className={classes.rowBgColor}
      columns={columns}
      pageSize={pageSize}
      autoHeight
      rowHeight={80}
      hideFooter
      disableSelectionOnClick
      onRowDoubleClick={({ row }) => {
        window.open(`/app/trans/${row.jid}`, '_blank');
      }}
    />
  );
};

export default Comments;
