import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography, makeStyles, Container } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundImage: `linear-gradient(180deg,rgba(255,266,255,0.5) 0%, rgba(32,160,222,.8) 98%), url(/static/images/landing-page/background-header-city-scape.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '150%',
    height: 750,
    width: '100%',
    paddingBottom: 50,
    backgroundSize: '140%',
    [theme.breakpoints.down('lg')]: {
      height: 550
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    }
  },

  headerTitle: {
    display: 'block',
    position: 'relative',
    fontWeight: 900,
    lineHeight: '4.3rem',
    color: '#fff',
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      lineHeight: '3rem',
      fontSize: '2.5rem',
      padding: '1em'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: '2.4rem',
      fontSize: '1.9rem',
      padding: '1em'
    }
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 100px)',
    paddingTop: '2em',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      marginBottom: '2em'
    },

    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 35px)',
      marginBottom: 0
    }
  },

  ulapBizImage: {
    height: 300,
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut} infinite alternate`,

    [theme.breakpoints.down('md')]: {
      height: 270
    },
    [theme.breakpoints.down('sm')]: {
      height: 250
    }
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'translateY(0)'
    },
    '100%': {
      transform: 'translateY(-1.5em)'
    }
  },
  shape: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    overflow: 'hidden',
    lineHeight: 0,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  svg: {
    position: 'relative',
    display: `block`,
    width: `calc(150% + 1.3px)`,
    height: `130px`,
    transform: `rotateY(180deg)`
  },
  shapeFill: {
    fill: `#FFFFFF`
  },
  containerButton: {
    display: 'flex',
    marginTop: theme.spacing(5),
    gap: '1em',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  button: {
    background: '#ff7704',
    color: '#fff',
    '&:hover': {
      background: '#ff7704',
      color: '#fff'
    }
  },
  automation: {
    fontSize: '1.1em'
  },
  business: {
    fontSize: '1.1em'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: '2em',

    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  indicator: {
    width: '20px',
    height: '35px',
    border: '2px solid #fff',
    borderRadius: '50px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '8px',
      height: '8px',
      background: '#fff',
      margin: 'auto',
      left: '0',
      right: '0',
      top: 0,
      borderRadius: '50%',
      animation: '$animate 1s linear infinite'
    }
  },
  '@keyframes animate': {
    '0%': {
      opacity: 1,
      top: 0
    },
    '100%': {
      opacity: 0,
      top: '35px',
      transform: 'scale(0.8)'
    }
  },
  scrollDown: {
    color: '#fff',
    marginTop: '.1em'
  },
  scrollDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Header = ({
  className,
  onGotoProductLine,
  onClickScrollDown,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.shape}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          className={classes.svg}
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className={classes.shapeFill}
          ></path>
        </svg>
      </div>

      <Container maxWidth="lg" style={{ height: '100%' }}>
        <Box className={classes.wrapper}>
          <Carousel
            className={classes.container}
            autoPlay
            animation="fade"
            duration={1000}
            stopAutoPlayOnHover={false}
            indicators={false}
            navButtonsAlwaysInvisible={true}
            swipe
          >
            <Typography className={classes.headerTitle}>
              Automation in the hands of every Businessman.
            </Typography>
            <Typography className={classes.headerTitle}>
              Your business in the cloud.
            </Typography>
            <Typography className={classes.headerTitle}>
              We work hard to make your work easy.
            </Typography>
          </Carousel>
          <Box flex={1} className={classes.imgContainer}>
            <img
              alt="logo"
              src="/static/images/landing-page/ulap-biz-logo.png"
              className={classes.ulapBizImage}
            />
          </Box>
        </Box>
        <Box
          className={classes.scrollDownContainer}
          onClick={onClickScrollDown}
        >
          <div className={classes.indicator} />
          <Typography
            variant="caption"
            diplay="block"
            className={classes.scrollDown}
          >
            Scroll Down
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
