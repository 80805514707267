import React, { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import clsx from 'clsx';
import AppLoadingOverlay from 'src/components/AppLoadingOverlay';
import usePageRefs from 'src/hooks/usePageRefs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#eee',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 64,
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  overflowAuto: {
    overflow: 'auto'
  }
}));

/**
 * @typedef {object} UlapBizContext
 * @property {string} activeSection
 * @property {(section: string) => void} setActiveSection
 */

/**
 * @type {React.Context<UlapBizContext>}
 */
const UlapBizContext = React.createContext();

const LandingPageLayout = () => {
  const classes = useStyles();
  const { scroller } = usePageRefs();

  const [activeSection, setActiveSection] = useState('');

  return (
    <UlapBizContext.Provider value={{ activeSection, setActiveSection }}>
      <div className={clsx(classes.root)}>
        <TopBar />
        <div className={clsx(classes.wrapper)}>
          <div className={clsx(classes.contentContainer)}>
            <div ref={scroller} className={clsx(classes.content)}>
              <Suspense fallback={<AppLoadingOverlay />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </UlapBizContext.Provider>
  );
};

export default LandingPageLayout;
export const useUlapBizLayout = () => React.useContext(UlapBizContext);
