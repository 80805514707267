import {
  BarChart,
  Description,
  Settings,
  TouchApp,
  DateRange
} from '@material-ui/icons';
import { isArray, merge } from 'lodash';
import { useSelector } from 'react-redux';
import { navGroups, reportList, setupList } from './navItems';
import { RU_PRIV_OVERRIDE } from 'src/constants';

const getList = (list, validator, isRU = false, allowBrch = false) =>
  list
    .filter(item => {
      if (item?.forceShow) return true;

      if (isRU && RU_PRIV_OVERRIDE.includes(item.key)) return true;

      if (item.priv === 'brch' && allowBrch) return true;

      if (isArray(item?.priv))
        return Boolean(item?.oneOfPriv)
          ? item.priv.some(priv => validator?.[priv])
          : item.priv.every(priv => validator?.[priv]);

      return validator?.[item.priv];
    })
    .map(item =>
      'children' in item
        ? {
            ...item,
            children: getList(item.children, validator, isRU, allowBrch)
          }
        : item
    );

function groupItems(list = [], validator = {}) {
  const res = [];

  const reqMet = {};

  Object.keys(navGroups).forEach(gKey => {
    reqMet[gKey] = navGroups[gKey].whenPresent.every(key => validator[key]);
  });

  list.forEach(item => {
    if (item?.group && item.group in navGroups && reqMet[item.group]) {
      const parent = res.find(i => i.key === item.group);

      if (parent) {
        parent.children.push(item);
      } else {
        res.push({
          key: item.group,
          title: navGroups[item.group].title,
          priv: navGroups[item.group].priv,
          children: [item]
        });
      }
    } else {
      res.push(item);
    }
  });

  return res;
}

const useGenerateNavItems = () => {
  const settings = useSelector(state => state.bizMeta);
  const { current_user = {} } = useSelector(state => state.auth);

  const transactions = settings?.transactions || [];
  const jskedGroups = settings?.jskedGroups || [];
  const dashboards = settings?.dashboards || [];
  const jskedProps = {};

  const hris = {};
  Object.entries(settings?.priv?.hris || {}).forEach(([key, val]) => {
    hris['hris.' + key] = val;
  });

  const setup = merge({}, settings?.priv?.setup || {}, hris);
  const reports = merge({}, settings?.priv?.reports || {}, hris);
  const jsked = merge({}, settings?.priv?.jsked || {}, hris);

  const brch = settings?.brch || {};

  const filteredTransactions = transactions
    .filter(item => {
      const jskedTransList = settings?.frontEnd?.jsked?.transList || [];

      if (jskedTransList.includes(item.JCd)) return false;

      return true;
    })
    .map(item => ({
      key: item.jcd_uuid,
      title: item.Description,
      href: '/app/trans/jcd/' + item.jcd_uuid,
      isVisible: true
    }));

  const groupedReports = groupItems(reportList, reports);
  const filteredReports = [
    ...getList(groupedReports, reports, current_user?.ru)
  ];

  if (jsked?.setup || jsked?.sked) {
    const jskedList = jskedGroups.map(item => ({
      key: 'grp-sked-' + item.ixSkedGrp,
      title: item.sSkedGrp,
      href: '/app/trans/jsked/grp/' + item.ixSkedGrp,
      priv: 'grp-sked-' + item.ixSkedGrp,
      isVisible: true
    }));

    if (jskedList.length) jskedProps.children = jskedList;
    else jskedProps.href = '/app/trans/jsked';
  }

  if (jsked?.report) {
    jskedGroups.forEach(item => {
      filteredReports.push({
        key: 'grp-sked-report-' + item.ixSkedGrp,
        title: item.sSkedGrp,
        href: '/app/reports/jsked/' + item.ixSkedGrp,
        priv: 'grp-sked-report-' + item.ixSkedGrp,
        isVisible: true
      });
    });
  }

  const filteredSetups = getList(
    setupList.map(item => {
      if (item.priv === 'brch') {
        return {
          ...item,
          title: brch?.label ?? 'Branches',
          href: '/app/setup/sub-accounts?sub-type=' + brch?.ixBrchSubType ?? 0
        };
      } else return item;
    }),
    setup,
    current_user?.ru,
    brch?.allow && brch?.mode > 0
  );

  const navItems = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      href: '/app/dashboard',
      icon: BarChart,
      isVisible: true
    },
    ...dashboards.map(db => ({
      key: db.ixDashboard,
      title: db.sDashboard,
      href: '/app/dashboard/' + db.ixDashboard,
      icon: BarChart,
      isVisible: true
    })),
    {
      key: 'transactions',
      title: 'Transactions',
      isVisible: filteredTransactions.length > 0,
      icon: TouchApp,
      children: filteredTransactions
    },
    {
      key: 'sked',
      title: 'Transaction Schedule',
      isVisible: jsked?.setup || jsked?.sked,
      icon: DateRange,
      ...jskedProps
    },
    {
      key: 'reports',
      title: 'Reports',
      icon: Description,
      isVisible: filteredReports.length > 0,
      isOpen: false,
      children: filteredReports
    },
    {
      key: 'settings',
      title: 'Settings',
      icon: Settings,
      isVisible: filteredSetups.length > 0,
      isOpen: false,
      children: filteredSetups
    }
  ];

  return navItems;
};

export default useGenerateNavItems;
