import { useState } from 'react';
import axios from 'axios';
import Base64 from 'Base64';

const valueTypes = [
  {
    key: 'username',
    caption: 'Username'
  },
  {
    key: 'phone',
    caption: 'Phone Number'
  },
  {
    key: 'email',
    caption: 'Email'
  }
];

const useResetPassword = () => {
  const [valueType, setValueType] = useState('');
  const [value, setValue] = useState('');
  const [requestSuccessResult, setRequestSuccessResult] = useState(null);
  const [requestFailedResult, setRequestFailedResult] = useState(null);

  const [otpSuccessResult, setOtpSuccessResult] = useState(null);
  const [otpFailedResult, setOtpFailedResult] = useState(null);

  const [form, setForm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  const [isResending, setIsResending] = useState(false);
  const [resendFailed, setResendFailed] = useState(null);

  const onChangeValueType = value => {
    setValueType(value);
  };

  const onChangeValue = value => {
    setValue(value);
  };

  const onChangeForm = value => {
    setForm(value);
  };

  const onChangeTimer = value => {
    setTimer(value);
  };

  const sendRequest = async ({ type, value: val }) => {
    setLoading(true);
    onChangeValueType(type);
    onChangeValue(val);
    setRequestFailedResult(null);
    setRequestSuccessResult(null);
    setTimer(0);
    try {
      const { data } = await axios({
        method: 'POST',
        url: '/api/user/request-pw-reset',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          type,
          value: val
        }
      });
      setLoading(false);
      setRequestSuccessResult(data);
      setForm(1);
      setTimer(30);
    } catch (error) {
      const errors = error?.response?.data;
      setRequestFailedResult(errors);
      setLoading(false);
    }
  };

  const resendOTP = async ({ type, value: val }) => {
    setIsResending(true);
    setResendFailed(null);
    setTimer(0);
    try {
      await axios({
        method: 'POST',
        url: '/api/user/request-pw-reset',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          type,
          value: val
        }
      });

      setIsResending(false);
      setTimer(30);
    } catch (error) {
      setIsResending(false);
      setResendFailed('Failed to resend code. Please try again.');
    }
  };

  const sendOTP = async ({ password, OTP }) => {
    setLoading(true);
    setOtpFailedResult(null);
    setOtpSuccessResult(null);

    const username =
      valueType !== 'username' ? requestSuccessResult?.username : value;

    try {
      const { data } = await axios({
        method: 'POST',
        url: '/api/user/pw-reset',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${Base64.btoa(`${username}:${password}`)}`
        },
        data: {
          code: OTP
        }
      });
      setLoading(false);
      setOtpSuccessResult(data);
      setForm(2);
    } catch (error) {
      setLoading(false);

      const errors = error?.response?.data;

      if (errors?.errors?.length > 0) {
        setOtpFailedResult(errors?.errors?.map(({ msg }) => msg).join());
        return;
      }

      setOtpFailedResult('Reset password failed. Please try again.');
    }
  };

  return {
    valueType,
    value,
    requestSuccessResult,
    requestFailedResult,
    otpSuccessResult,
    otpFailedResult,
    form,
    valueTypes,
    loading,
    timer,
    isResending,
    resendFailed,
    onChangeValueType,
    onChangeValue,
    onChangeForm,
    sendRequest,
    sendOTP,
    resendOTP,
    onChangeTimer
  };
};

export default useResetPassword;
