import { isNumber, round } from 'lodash';

const LOGS_FIELD = [
  'BIO1',
  'BIO2',
  'BIO3',
  'BIO4',
  'BIO5',
  'BIO6',
  'BIO7',
  'BIO8',
  'BIO9',
  'BIO10',
  'BIO11',
  'BIO12'
];

const logDefaultProps = {
  ixPR: '',
  ixEmp: '',

  ixDate: 0,
  snBIO: '',
  empBioID: '',
  RestDay: 0,

  BIO1: 0,
  BIO2: 0,
  BIO3: 0,
  BIO4: 0,
  BIO5: 0,
  BIO6: 0,
  BIO7: 0,
  BIO8: 0,
  BIO9: 0,
  BIO10: 0,
  BIO11: 0,
  BIO12: 0,

  SHF1_IN: 0,
  SHF1_OUT: 0,
  SHF2_IN: 0,
  SHF2_OUT: 0,
  SHF1_HR_IN: 0,
  SHF1_HR_OUT: 0,
  SHF1_HR_remarks: '',
  SHF2_HR_IN: 0,
  SHF2_HR_OUT: 0,
  SHF2_HR_remarks: '',

  SYS_AM_IN: 0,
  SYS_AM_OUT: 0,
  SYS_PM_IN: 0,
  SYS_PM_OUT: 0,
  SYS_OT_IN: 0,
  SYS_OT_OUT: 0,
  SYS_remarks: '',

  EMP_AM_IN: 0,
  EMP_AM_OUT: 0,
  EMP_PM_IN: 0,
  EMP_PM_OUT: 0,
  EMP_OT_IN: 0,
  EMP_OT_OUT: 0,
  EMP_remarks: '',

  HR_AM_IN: 0,
  HR_AM_OUT: 0,
  HR_PM_IN: 0,
  HR_PM_OUT: 0,
  HR_OT_IN: 0,
  HR_OT_OUT: 0,
  HR_remarks: '',

  // auto compute variables by the system
  svOT: 0,
  svOT_RH: 0,
  svOT_SH: 0,
  svOT_ND: 0,
  svOT_ND_RH: 0,
  svOT_ND_SH: 0,
  svOT_RD: 0,
  svOT_RD_OT: 0,
  svOT_RD_RH: 0,
  svOT_RD_SH: 0,

  svND: 0,
  svND_RH: 0,
  svND_SH: 0,
  svND_RD: 0,
  svND_RD_RH: 0,
  svND_RD_SH: 0,

  svPresent: 0,
  svHrs: 0,
  svAbsent: 0,
  svLate: 0,
  svUT: 0,
  svOB: 0,
  svRH: 0,
  svSH: 0,
  svSL: 0,
  svVL: 0,
  svML: 0,
  svPL: 0,
  svAWOL: 0,

  svar1: 0,
  svar2: 0,
  svar3: 0,
  svar4: 0,
  svar5: 0,
  svar6: 0,
  svar7: 0,
  svar8: 0,
  svar9: 0,

  // variables for override of hr
  vOT: 0,
  vOT_RH: 0,
  vOT_SH: 0,
  vOT_ND: 0,
  vOT_ND_RH: 0,
  vOT_ND_SH: 0,
  vOT_RD: 0,
  vOT_RD_OT: 0,
  vOT_RD_RH: 0,
  vOT_RD_SH: 0,

  vND: 0,
  vND_RH: 0,
  vND_SH: 0,
  vND_RD: 0,
  vND_RD_RH: 0,
  vND_RD_SH: 0,

  vPresent: 0,
  vHrs: 0,
  vAbsent: 0,
  vLate: 0,
  vUT: 0,
  vOB: 0,
  vRH: 0,
  vSH: 0,
  vSL: 0,
  vVL: 0,
  vML: 0,
  vPL: 0,
  vAWOL: 0,

  var1: 0,
  var2: 0,
  var3: 0,
  var4: 0,
  var5: 0,
  var6: 0,
  var7: 0,
  var8: 0,
  var9: 0,

  bOverriden: 0
};

class Log {
  ixPR;
  ixEmp;

  ixDate;
  snBIO;
  empBioID;
  RestDay;

  BIO1;
  BIO2;
  BIO3;
  BIO4;
  BIO5;
  BIO6;
  BIO7;
  BIO8;
  BIO9;
  BIO10;
  BIO11;
  BIO12;

  SHF1_IN;
  SHF1_OUT;
  SHF2_IN;
  SHF2_OUT;
  SHF1_HR_IN;
  SHF1_HR_OUT;
  SHF1_HR_remarks;
  SHF2_HR_IN;
  SHF2_HR_OUT;
  SHF2_HR_remarks;

  SYS_AM_IN;
  SYS_AM_OUT;
  SYS_PM_IN;
  SYS_PM_OUT;
  SYS_OT_IN;
  SYS_OT_OUT;
  SYS_remarks;

  EMP_AM_IN;
  EMP_AM_OUT;
  EMP_PM_IN;
  EMP_PM_OUT;
  EMP_OT_IN;
  EMP_OT_OUT;
  EMP_remarks;

  HR_AM_IN;
  HR_AM_OUT;
  HR_PM_IN;
  HR_PM_OUT;
  HR_OT_IN;
  HR_OT_OUT;
  HR_remarks;

  svOT;
  svOT_RH;
  svOT_SH;
  svOT_ND;
  svOT_ND_RH;
  svOT_ND_SH;
  svOT_RD;
  svOT_RD_OT;
  svOT_RD_RH;
  svOT_RD_SH;

  svND;
  svND_RH;
  svND_SH;
  svND_RD;
  svND_RD_RH;
  svND_RD_SH;

  svPresent;
  svHrs;
  svAbsent;
  svLate;
  svUT;
  svOB;
  svRH;
  svSH;
  svSL;
  svVL;
  svML;
  svPL;
  svAWOL;

  svar1;
  svar2;
  svar3;
  svar4;
  svar5;
  svar6;
  svar7;
  svar8;
  svar9;

  vOT;
  vOT_RH;
  vOT_SH;
  vOT_ND;
  vOT_ND_RH;
  vOT_ND_SH;
  vOT_RD;
  vOT_RD_OT;
  vOT_RD_RH;
  vOT_RD_SH;

  vND;
  vND_RH;
  vND_SH;
  vND_RD;
  vND_RD_RH;
  vND_RD_SH;

  vPresent;
  vHrs;
  vAbsent;
  vLate;
  vUT;
  vOB;
  vRH;
  vSH;
  vSL;
  vVL;
  vML;
  vPL;
  vAWOL;

  var1;
  var2;
  var3;
  var4;
  var5;
  var6;
  var7;
  var8;
  var9;

  bOverriden;

  constructor(log = logDefaultProps) {
    const props = { ...logDefaultProps, ...log };

    this.ixPR = props.ixPR;
    this.ixEmp = props.ixEmp;

    this.ixDate = props.ixDate;
    this.snBIO = props.snBIO;
    this.empBioID = props.empBioID;
    this.RestDay = props.RestDay;

    this.BIO1 = props.BIO1;
    this.BIO2 = props.BIO2;
    this.BIO3 = props.BIO3;
    this.BIO4 = props.BIO4;
    this.BIO5 = props.BIO5;
    this.BIO6 = props.BIO6;
    this.BIO7 = props.BIO7;
    this.BIO8 = props.BIO8;
    this.BIO9 = props.BIO9;
    this.BIO10 = props.BIO10;
    this.BIO11 = props.BIO11;
    this.BIO12 = props.BIO12;

    this.SHF1_IN = props.SHF1_IN;
    this.SHF1_OUT = props.SHF1_OUT;
    this.SHF2_IN = props.SHF2_IN;
    this.SHF2_OUT = props.SHF2_OUT;
    this.SHF1_HR_IN = props.SHF1_HR_IN;
    this.SHF1_HR_OUT = props.SHF1_HR_OUT;
    this.SHF1_HR_remarks = props.SHF1_HR_remarks;
    this.SHF2_HR_IN = props.SHF2_HR_IN;
    this.SHF2_HR_OUT = props.SHF2_HR_OUT;
    this.SHF2_HR_remarks = props.SHF2_HR_remarks;

    this.SYS_AM_IN = props.SYS_AM_IN;
    this.SYS_AM_OUT = props.SYS_AM_OUT;
    this.SYS_PM_IN = props.SYS_PM_IN;
    this.SYS_PM_OUT = props.SYS_PM_OUT;
    this.SYS_OT_IN = props.SYS_OT_IN;
    this.SYS_OT_OUT = props.SYS_OT_OUT;
    this.SYS_remarks = props.SYS_remarks;

    this.EMP_AM_IN = props.EMP_AM_IN;
    this.EMP_AM_OUT = props.EMP_AM_OUT;
    this.EMP_PM_IN = props.EMP_PM_IN;
    this.EMP_PM_OUT = props.EMP_PM_OUT;
    this.EMP_OT_IN = props.EMP_OT_IN;
    this.EMP_OT_OUT = props.EMP_OT_OUT;
    this.EMP_remarks = props.EMP_remarks;

    this.HR_AM_IN = props.HR_AM_IN;
    this.HR_AM_OUT = props.HR_AM_OUT;
    this.HR_PM_IN = props.HR_PM_IN;
    this.HR_PM_OUT = props.HR_PM_OUT;
    this.HR_OT_IN = props.HR_OT_IN;
    this.HR_OT_OUT = props.HR_OT_OUT;
    this.HR_remarks = props.HR_remarks;

    this.svPresent = props.svPresent;
    this.svOT = props.svOT;
    this.svOT_RH = props.svOT_RH;
    this.svOT_SH = props.svOT_SH;
    this.svOT_ND = props.svOT_ND;
    this.svOT_ND_RH = props.svOT_ND_RH;
    this.svOT_ND_SH = props.svOT_ND_SH;
    this.svOT_RD = props.svOT_RD;
    this.svOT_RD_OT = props.svOT_RD_OT;
    this.svOT_RD_RH = props.svOT_RD_RH;
    this.svOT_RD_SH = props.svOT_RD_SH;
    this.svAWOL = props.svAWOL;

    this.svND = props.svND;
    this.svND_RH = props.svND_RH;
    this.svND_SH = props.svND_SH;
    this.svND_RD = props.svND_RD;
    this.svND_RD_RH = props.svND_RD_RH;
    this.svND_RD_SH = props.svND_RD_SH;

    this.svHrs = props.svHrs;
    this.svAbsent = props.svAbsent;
    this.svLate = props.svLate;
    this.svUT = props.svUT;
    this.svOB = props.svOB;
    this.svRH = props.svRH;
    this.svSH = props.svSH;
    this.svSL = props.svSL;
    this.svVL = props.svVL;
    this.svML = props.svML;
    this.svPL = props.svPL;

    this.vOT = props.vOT;
    this.vOT_RH = props.vOT_RH;
    this.vOT_SH = props.vOT_SH;
    this.vOT_ND = props.vOT_ND;
    this.vOT_ND_RH = props.vOT_ND_RH;
    this.vOT_ND_SH = props.vOT_ND_SH;
    this.vOT_RD = props.vOT_RD;
    this.vOT_RD_OT = props.vOT_RD_OT;
    this.vOT_RD_RH = props.vOT_RD_RH;
    this.vOT_RD_SH = props.vOT_RD_SH;

    this.vND = props.vND;
    this.vND_RH = props.vND_RH;
    this.vND_SH = props.vND_SH;
    this.vND_RD = props.vND_RD;
    this.vND_RD_RH = props.vND_RD_RH;
    this.vND_RD_SH = props.vND_RD_SH;

    this.vPresent = props.vPresent;
    this.vHrs = props.vHrs;
    this.vAbsent = props.vAbsent;
    this.vLate = props.vLate;
    this.vUT = props.vUT;
    this.vOB = props.vOB;
    this.vRH = props.vRH;
    this.vSH = props.vSH;
    this.vSL = props.vSL;
    this.vVL = props.vVL;
    this.vML = props.vML;
    this.vPL = props.vPL;
    this.vAWOL = props.vAWOL;

    this.var1 = props.var1;
    this.var2 = props.var2;
    this.var3 = props.var3;
    this.var4 = props.var4;
    this.var5 = props.var5;
    this.var6 = props.var6;
    this.var7 = props.var7;
    this.var8 = props.var8;
    this.var9 = props.var9;

    this.svar1 = props.svar1;
    this.svar2 = props.svar2;
    this.svar3 = props.svar3;
    this.svar4 = props.svar4;
    this.svar5 = props.svar5;
    this.svar6 = props.svar6;
    this.svar7 = props.svar7;
    this.svar8 = props.svar8;
    this.svar9 = props.svar9;

    this.bOverriden = props.bOverriden;
  }

  /**
   *
   * @param {Log} data
   */
  update(data = {}) {
    Object.keys(data).forEach(key => {
      if (key in this)
        this[key] = isNumber(data[key]) ? round(data[key], 2) : data[key];
    });
  }

  /**
   *
   * @returns {number[]}
   */
  getLogs() {
    const bioLogs = [];

    for (const field of LOGS_FIELD) {
      if (!this[field]) break;

      bioLogs.push(this[field]);
    }

    return bioLogs;
  }
}

export default Log;
