import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { merge, round } from 'lodash';

const defaultLoadingDisplay = {
  display: () => {},
  hide: () => {}
};

class Request {
  #base_url;
  #config;
  #loadingDisplay;

  /**
   * @private
   * @param {String} url
   * @returns {String}
   */
  addBaseUrl(url) {
    return this.#base_url + url;
  }

  /**
   * @private
   */
  successFormatter(response) {
    return {
      success: true,
      data: response.data
    };
  }

  /**
   * @private
   */
  errorFormatter(error) {
    return {
      success: false,
      isCancelled: axios.isCancel(error),
      error: error.response,
      errorMessage:
        error?.response?.data?.message ||
        error?.response?.data?.description ||
        error?.response?.data?.msg ||
        error?.response?.data ||
        'Something went wrong'
    };
  }

  constructor(base_url, config, loadingDisplay = defaultLoadingDisplay) {
    this.#base_url = base_url;
    this.#config = config;
    this.#loadingDisplay = loadingDisplay;
  }

  /**
   *
   * @param {AxiosRequestConfig[]} requests
   * @param {cancelCallBack} cancelCallback
   * @param {Function} progressCallback
   * @returns {Promise<requestResponse[]>}
   */
  async multiFetch(
    requests = [],
    cancelCallback = () => {},
    progressCallback = () => {}
  ) {
    let finishedRequests = 0;
    const totalRequests = requests.length;

    progressCallback(0);

    this.#loadingDisplay.display();

    const promises = requests.map(request =>
      request?.imaginary || false
        ? (async () => null)()
        : this.fetch2(request, cancelCallback)
    );

    for (const promise of promises) {
      promise.then(() => {
        progressCallback(round((++finishedRequests * 100) / totalRequests));
      });
    }

    const results = await Promise.all(promises);

    this.#loadingDisplay.hide();
    return results;
  }

  /**
   * @private
   */
  async fetch2(
    { method = 'GET', url = '', data = {}, ...props },
    cancelCallback = () => {}
  ) {
    const result = await axios({
      method,
      url: this.addBaseUrl(url),
      data,
      ...this.#config,
      ...props,
      cancelToken: new axios.CancelToken(cancelCallback)
    })
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });

    return result;
  }

  /**
   *
   * @param {AxiosRequestConfig} param0
   * @param {cancelCallBack} cancelCallback
   * @returns
   */
  async fetch(
    { method = 'GET', url = '', data = {}, ...props },
    cancelCallback = () => {}
  ) {
    this.#loadingDisplay.display();
    const result = await axios({
      method,
      url: this.addBaseUrl(url),
      data,
      ...this.#config,
      ...props,
      cancelToken: new axios.CancelToken(cancelCallback)
    })
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });
    this.#loadingDisplay.hide();
    return result;
  }

  /**
   * @private
   * @param {String} url
   * @param {AxiosRequestConfig} config
   */
  async axiosGet(url, config) {
    this.#loadingDisplay.display();
    const result = await axios
      .get(url, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });
    this.#loadingDisplay.hide();
    return result;
  }

  /**
   * @private
   * @param {String} url
   * @param {{}} data
   * @param {AxiosRequestConfig} config
   */
  async axiosPost(url, data, config) {
    this.#loadingDisplay.display();
    const result = await axios
      .post(url, data, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });
    this.#loadingDisplay.hide();

    return result;
  }

  /**
   * @private
   * @param {String} url
   * @param {{}} data
   * @param {AxiosRequestConfig} config
   */
  async axiosPut(url, data, config) {
    this.#loadingDisplay.display();
    const result = await axios
      .put(url, data, config)
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });
    this.#loadingDisplay.hide();

    return result;
  }

  /**
   * @private
   * @param {String} url
   * @param {{}} data
   * @param {AxiosRequestConfig} config
   */
  async axiosDelete(url, data, config) {
    this.#loadingDisplay.display();
    const result = await axios
      .delete(url, {
        ...config,
        data
      })
      .then(response => {
        return this.successFormatter(response);
      })
      .catch(error => {
        return this.errorFormatter(error);
      });
    this.#loadingDisplay.hide();

    return result;
  }

  /**
   * @private
   * @param {AxiosRequestConfig} config
   * @param {cancelCallBack} cancelCallBack
   */
  mergeConfig(config, cancelCallBack) {
    return merge({}, this.#config, config, {
      cancelToken: new axios.CancelToken(cancelCallBack)
    });
  }

  /**
   *
   * @param {String} url
   * @param {AxiosRequestConfig} config
   * @param {cancelCallBack} cancelCallback
   * @returns {Promise<requestResponse>}
   */
  get(url, config = {}, cancelCallback = () => {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosGet(urlWithBase, this.mergeConfig(config, cancelCallback));
  }

  /**
   *
   * @param {String} url
   * @param {Object} data
   * @param {AxiosRequestConfig} config
   * @param {cancelCallBack} cancelCallback
   * @returns {Promise<requestResponse>}
   */
  post(url, data = {}, config = {}, cancelCallback = () => {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosPost(
      urlWithBase,
      data,
      this.mergeConfig(config, cancelCallback)
    );
  }

  /**
   *
   * @param {String} url
   * @param {Object} data
   * @param {AxiosRequestConfig} config
   * @param {cancelCallBack} cancelCallback
   * @returns {Promise<requestResponse>}
   */
  put(url, data = {}, config = {}, cancelCallback = () => {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosPut(
      urlWithBase,
      data,
      this.mergeConfig(config, cancelCallback)
    );
  }

  /**
   *
   * @param {String} url
   * @param {Object} data
   * @param {AxiosRequestConfig} config
   * @param {cancelCallBack} cancelCallback
   * @returns {Promise<requestResponse>}
   */
  delete(url, data = {}, config = {}, cancelCallback = () => {}) {
    const urlWithBase = this.addBaseUrl(url);
    return this.axiosDelete(
      urlWithBase,
      data,
      this.mergeConfig(config, cancelCallback)
    );
  }

  overrideLoadingDisplay(loadingDisplay = defaultLoadingDisplay) {
    return new Request(this.#base_url, this.#config, loadingDisplay);
  }

  overrideWholeConfig(newConfig = {}) {
    return new Request(this.#base_url, newConfig, this.#loadingDisplay);
  }

  removeBaseUrl() {
    return new Request('', this.#config, this.#loadingDisplay);
  }
}

export default Request;
