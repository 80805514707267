import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import useReveal, { Animated } from 'src/hooks/useReveal';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    width: '100%',
    paddingTop: 100,
    paddingBottom: 100,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      height: '100%',
      backgroundSize: 'auto'
    }
  },
  mainTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      textAlign: 'center'
    }
  },

  headTitle: {
    fontSize: '1em',
    lineHeight: '.5em',
    fontWeight: 900,
    color: '#fff'
  },
  card: ({ color }) => {
    return {
      backgroundColor: color,
      padding: '2.5em',
      borderRadius: '1em',
      boxShadow: `rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px`
    };
  },
  planTitle: {
    fontSize: '2.3em',
    color: '#fff',
    fontWeight: 900,
    marginBottom: '.3em'
  },
  availNow: ({ color }) => {
    return {
      backgroundColor: '#fff',
      color: color,
      marginBottom: '.3em',

      '&:hover': {
        backgroundColor: '#fff',
        color: color
      }
    };
  },
  freeTrial: {
    color: '#fff',
    fontSize: '.8em',
    textAlign: 'center'
  }
}));

const PlanCards = ({ color, plan, delay, reveal }) => {
  const classes = useStyles({ color });

  return (
    <Animated
      config={{ duration: 500 }}
      delay={delay}
      to={{
        opacity: reveal ? 1 : 0,
        y: reveal ? 0 : 100
      }}
    >
      <Box className={classes.card}>
        <Box>
          <Typography className={classes.headTitle}>UlapBiz</Typography>
          <Typography className={classes.planTitle}>{plan}</Typography>
        </Box>
        <Button
          className={classes.availNow}
          fullWidth
          component={RouterLink}
          to="/contact-us"
          size="large"
        >
          Avail Now
        </Button>
        <Typography className={classes.freeTrial}>
          FREE 30-DAY TRIAL FOR ANNUAL CONTRACT
        </Typography>
      </Box>
    </Animated>
  );
};

const PLANS = [
  {
    plan: 'Basic',
    color: '#1976D2'
  },
  {
    plan: 'Pro',
    color: '#000'
  },
  {
    plan: 'ERP',
    color: '#81C784'
  }
];

const Plans = ({ className, ...rest }) => {
  const classes = useStyles();
  const [ref, reveal] = useReveal({ threshold: 0.1 });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <div ref={ref}>
          <Animated
            config={{ duration: 500 }}
            to={{
              opacity: reveal ? 1 : 0,
              y: reveal ? 0 : -50
            }}
          >
            <Typography className={classes.mainTitle} align="center">
              Tara! Tell us what you need.
            </Typography>
          </Animated>
          <Box mt="5em">
            <Grid container spacing={6}>
              {PLANS.map((data, i) => (
                <Grid item xs={12} md={4} key={data.plan}>
                  <PlanCards
                    plan={data.plan}
                    color={data.color}
                    delay={200 * i}
                    reveal={reveal}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      </Container>
    </div>
  );
};

Plans.propTypes = {
  className: PropTypes.string
};

export default Plans;
