import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  useTheme,
  IconButton,
  Tooltip,
  useMediaQuery,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NavItem from './NavItem';
import NavItemHeader from './NavItemHeader';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGenerateNavItems from './useGenerateNavItems';
import { setSideBarState } from 'src/redux/slices/biz';
import clsx from 'clsx';
import useToggle from 'src/hooks/useToggleV2';

import { SIDEBAR_WIDTH, NAVBAR_HEIGHT } from 'src/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import CashierLog from './CashierLog';
import { Inbox } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: SIDEBAR_WIDTH
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  nestedList: {
    paddingLeft: theme.spacing(1)
  },
  drawer: {
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: NAVBAR_HEIGHT,
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`
  },
  drawerOpen: {
    width: SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginTop: NAVBAR_HEIGHT,
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: NAVBAR_HEIGHT,
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(1)
  },
  sidebarExpanded: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  btnPin: {
    position: 'absolute',
    top: 6,
    right: 12
  }
}));

const NavBar = ({ isSidebarOpen, closeSidebar, toggleSidebar }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { current_user, sandbox } = useSelector(state => state.auth);
  const { sidebarState = {}, frontEnd, ru_note } = useSelector(
    state => state.bizMeta
  );
  const showCashierLog =
    frontEnd?.cashierLog?.show &&
    (frontEnd?.cashierLog?.users ?? []).includes(current_user.username);

  const navItems = useGenerateNavItems();
  const [isExpanded, maximize, minimize] = useToggle();
  const [isTransitionEnded, transitionEnded, resetTransition] = useToggle();

  const isCollapsed = isSidebarOpen || isExpanded;
  const showCaption = isSidebarOpen || (isTransitionEnded && isExpanded);

  const visibleNavItems = navItems.filter(item => item?.isVisible);

  visibleNavItems.forEach(item => {
    if ('children' in item && !(item.key in sidebarState)) {
      dispatch(setSideBarState({ [item.key]: item?.isOpen }));
    }
  });

  function onHeaderClick(key) {
    dispatch(setSideBarState({ [key]: !sidebarState[key] }));
  }

  function handleMinimize() {
    if (isSidebarOpen) return;
    minimize();
  }

  function handleMaximize() {
    if (isSidebarOpen) return;
    maximize();
  }

  function handleTransitionEnd() {
    if (isExpanded) transitionEnded();
    else resetTransition();
  }

  function handleNavClick() {
    if (onSmallScreen) closeSidebar();
  }

  function generateNav(items) {
    return (
      <List disablePadding>
        {items.map(item => {
          const hasChildren = 'children' in item;
          return hasChildren ? (
            <NavItemHeader
              icon={item.icon}
              key={item.key}
              title={item.title}
              isOpen={sidebarState?.[item.key]}
              children={item.children}
              generateNav={generateNav}
              onClick={() => onHeaderClick(item.key)}
              isCollapsed={isCollapsed}
            />
          ) : (
            <NavItem
              key={item.key}
              title={item.title}
              href={item.href}
              icon={item.icon}
              isCollapsed={isCollapsed}
              onClick={handleNavClick}
            />
          );
        })}
      </List>
    );
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        p={2}
        gridColumnGap={theme.spacing(2)}
        justifyContent={isCollapsed ? 'flex-start' : 'center'}
        position="relative"
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={`/api/images/user/${current_user.username}/avatar.jpg`}
          to="/app/account"
        />

        {showCaption && (
          <>
            <Box overflow="hidden">
              <Typography
                color="textPrimary"
                variant="h5"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {current_user.full_name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="caption"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {current_user.position}
              </Typography>
            </Box>
            <Hidden mdDown>
              <Tooltip
                title={isSidebarOpen ? 'Unpin Sidebar' : 'Pin Sidebar'}
                className={classes.btnPin}
              >
                <IconButton
                  size="small"
                  color={isSidebarOpen ? 'primary' : 'default'}
                  disableRipple
                  disableTouchRipple
                  onClick={toggleSidebar}
                >
                  <FontAwesomeIcon icon={faThumbTack} />
                </IconButton>
              </Tooltip>
            </Hidden>
          </>
        )}
      </Box>
      {Boolean(ru_note) && ru_note !== '' && (
        <>
          <Divider />
          <Box p={1}>
            <Alert severity="warning">{isCollapsed ? ru_note : ''}</Alert>
          </Box>
        </>
      )}
      {Boolean(sandbox) && (
        <>
          <Divider />
          <Box p={1}>
            <Alert severity="info" icon={<Inbox />}>
              {isCollapsed ? sandbox.sDesc : ''}
            </Alert>
          </Box>
        </>
      )}
      <Divider />
      {isCollapsed && showCashierLog && (
        <Box p={2}>
          <CashierLog />
        </Box>
      )}
      {isCollapsed && showCashierLog && <Divider />}
      <Box className={isCollapsed ? classes.sidebarExpanded : classes.sidebar}>
        {generateNav(visibleNavItems)}
      </Box>
      <Box flexGrow={1} />
      <Box
        p={2}
        m={1}
        bgcolor="background.dark"
        hidden={!showCaption}
        whiteSpace="pre-wrap"
      >
        <Typography className={classes.name} color="textPrimary" variant="h5">
          Radztech Business Solutions
        </Typography>
        <Typography color="textSecondary" variant="caption">
          San Fermin, Cauayan City, Isabela, 3305 Philippines
        </Typography>
        <Box mt={1} display="flex" justifyContent="flex-end">
          <Typography color="textSecondary" align="center" variant="caption">
            version {process?.env?.REACT_APP_VERSION ?? ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {/* Small Screen - md down */}
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={closeSidebar}
          open={isSidebarOpen}
        >
          <PerfectScrollbar>{content}</PerfectScrollbar>
        </Drawer>
      </Hidden>
      {/* Large Screen - lg up */}
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          onMouseEnter={handleMaximize}
          onMouseLeave={handleMinimize}
          onTransitionEnd={handleTransitionEnd}
          anchor="left"
          open={isCollapsed}
          className={clsx(
            classes.drawer,
            isCollapsed ? classes.drawerOpen : classes.drawerClose
          )}
          classes={{
            paper: isCollapsed ? classes.drawerOpen : classes.drawerClose
          }}
        >
          <PerfectScrollbar>{content}</PerfectScrollbar>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  openSidebar: PropTypes.func,
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool
};

NavBar.defaultProps = {
  openSidebar: () => {},
  toggleSidebar: () => {},
  isSidebarOpen: false
};

export default NavBar;
