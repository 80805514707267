import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  Typography,
  makeStyles,
  Popover,
  Divider,
  Tooltip,
  Chip,
  Link,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import { useSelector } from 'react-redux';
import { useRequest, useToggle } from 'src/hooks';

const useStyles = makeStyles(theme => ({
  item: {
    justifyContent: 'space-between',
    gridGap: '1em',
    height: 60,
    '&:nth-child(even)': {
      backgroundColor: theme.palette.background.default
    },
    [theme.breakpoints.down('sm')]: {
      height: 100
    },
    [theme.breakpoints.down('xs')]: {
      height: 100
    }
  },
  bgColor: {
    backgroundColor: theme.palette.background.dark
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

function Item({ account, onSelectAccount, ...rest }) {
  const classes = useStyles();
  const request = useRequest();
  const [accountInfo, setAccountInfo] = useState({});
  const [anchorElement, setAnchorElement] = useState(null);
  const [loading, loadOn, loadOff] = useToggle();
  const { budget } = useSelector(state => state.bizMeta);

  const isParentAccount = account.iRgt - account.iLft !== 1;

  const getLink = () => {
    const accTitle = account?.sAccTitle?.replaceAll('/', '_');
    const timeStart = 'T00:00:00';
    const timeEnd = 'T23:59:59';
    const date = moment();
    const start = date.startOf('month').format('YYYY-MM-DD') + timeStart;
    const end = date.endOf('month').format('YYYY-MM-DD') + timeEnd;
    const sDate =
      'For the month ended ' + date.endOf('month').format('MMMM, DD YYYY');
    return `/app/reports/gl/id/${account?.ixAcc}/${accTitle}/start/${start}/end/${end}/sDate/${sDate}/brch/0/action/submit`;
  };

  const link = getLink();

  const getAccountDetails = async cancel => {
    loadOn();
    const { success, data } = await request.get(
      `/setup/acc/${account.ixAcc}`,
      {},
      cancel
    );
    loadOff();

    if (success) setAccountInfo(data);
  };

  const handleClickInfo = e => {
    e.preventDefault();
    e.stopPropagation();
    const cancel = () => null;
    getAccountDetails(cancel);
    setAnchorElement(e.currentTarget);
  };

  const handleSelectAccount = e => {
    e.preventDefault();
    e.stopPropagation();
    onSelectAccount(account);
  };

  const handleCloseInfo = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElement(null);
  };

  const handleClickViewGL = e => {
    e.preventDefault();
    e.stopPropagation();
    window.open(link, '_blank');
  };

  return (
    <ListItem
      key={account.ixAcc}
      button
      alignItems="center"
      className={classes.item}
      onClick={handleSelectAccount}
      {...rest}
    >
      <Box display="flex" gridGap=".5em" className={classes.container}>
        <Box width={100}>
          <Typography
            variant="caption"
            align="left"
            display="block"
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span>{account.ixAcc}</span>
            <span>
              <b> {account.AccNo}</b>
            </span>
          </Typography>
        </Box>

        <Box
          ml={`${account.indent_lvl}em`}
          display="flex"
          alignItems="center"
          flex={1}
          mr="auto"
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: isParentAccount ? 900 : ''
            }}
          >
            {account.sAccTitle}{' '}
          </Typography>

          <Popover
            id={Boolean(anchorElement) ? 'account-info' : undefined}
            open={Boolean(anchorElement)}
            anchorEl={anchorElement}
            onClose={handleCloseInfo}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {loading ? (
              <Box
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Box p={2} width={250}>
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                  gridGap="1em"
                  mb={1}
                >
                  <Typography variant="caption" color="primary">
                    {account.sAccTitle}
                  </Typography>
                  <Box>
                    <IconButton size="small" onClick={handleCloseInfo}>
                      <CloseIcon color="inherit" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" gridGap="1em" mt=".5em">
                  <Typography variant="caption" color="textPrimary">
                    <strong> ID : </strong> {account.ixAcc}
                  </Typography>
                  {account.AccNo !== '' && (
                    <Typography variant="caption" color="textPrimary">
                      <strong>
                        {budget?.allow ? 'UACS Code' : 'Acc No.'} :{' '}
                      </strong>
                      {account.AccNo}
                    </Typography>
                  )}
                </Box>

                {account.parent_sAccTitle !== null &&
                  account.parent_sAccTitle !== '' && (
                    <Box mt=".5em">
                      <Typography variant="caption" color="textPrimary">
                        <strong> Parent Acc. : </strong>{' '}
                        {account.parent_sAccTitle}
                      </Typography>
                    </Box>
                  )}

                {accountInfo.sAccContra !== '' &&
                  accountInfo.sAccContra !== null && (
                    <Box mt=".5em">
                      <Typography variant="caption" color="textPrimary">
                        <strong> Contra Acc. : </strong>{' '}
                        {accountInfo.sAccContra}
                      </Typography>{' '}
                    </Box>
                  )}

                {[1, 2, 3, 4, 5].map(item =>
                  Boolean(accountInfo[`sSubType${item}`]) ? (
                    <Box key={item} mt={1}>
                      <Typography variant="caption">
                        <strong> Sub-Acc {item} : </strong>{' '}
                        {accountInfo[`sSubType${item}`]}
                      </Typography>
                    </Box>
                  ) : null
                )}

                {accountInfo.acc_explain !== '' && (
                  <Box mt={1}>
                    <Typography variant="caption">
                      <strong> Explanation : </strong> {accountInfo.acc_explain}
                    </Typography>
                  </Box>
                )}

                {accountInfo.acc_remarks !== '' && (
                  <Box mt={1}>
                    <Typography variant="caption">
                      <strong> Remarks : </strong> {accountInfo.acc_remarks}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Popover>
        </Box>
      </Box>

      <Box display="flex" gridGap=".3em" alignItems="center">
        <Chip size="small" color="primary" label={account.sAccType} />

        <Tooltip title="More Info">
          <IconButton
            color="primary"
            size="small"
            tabIndex={-1}
            onClick={handleClickInfo}
            disabled={loading}
            aria-describedby={
              Boolean(anchorElement) ? 'account-info' : undefined
            }
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Account GL">
          <IconButton
            component={Link}
            color="primary"
            size="small"
            tabIndex={-1}
            href={link}
            onClick={handleClickViewGL}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </ListItem>
  );
}

function renderRow(props) {
  const { index, style, data } = props;

  return (
    <Item
      account={data.accounts[index]}
      onSelectAccount={data.onSelectAccount}
      style={style}
    />
  );
}

function Result({ accounts = [], onSelectAccount }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = sm || xs;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={accounts.length}
          itemData={{ accounts, onSelectAccount }}
          itemSize={isSmallScreen ? 100 : 60}
          width={width}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}

export default Result;
