import moment from 'moment';

export function formatTimeToInt(time) {
  return time ? parseInt(moment(time, 'HH:mm').format('HHmm')) : 0;
}

export function formatDateToInt(date) {
  return parseInt(moment(date).format('YYYYMMDD'));
}

export function formatDateToStr(date) {
  const strDate = date.toString();

  return (
    strDate.slice(0, 4) + '-' + strDate.slice(4, 6) + '-' + strDate.slice(6)
  );
}

export function formatTimeToStr(time = 0, format = 'HH:mm') {
  if (!time) return '';

  let strTime = time.toString();

  if (time < 100) strTime = '00' + strTime;
  else if (time < 1000) strTime = '0' + strTime;

  return moment(strTime, 'HHmm').format(format);
}

export function formatDateToStrWithDay(date) {
  const formattedDate = formatDateToStr(date);
  return moment(formattedDate).format('YYYY-MM-DD (ddd)');
}
